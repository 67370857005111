/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 01/02/24
 * Time: 9:04 pm
 * Project: landing_react
 */
import React from 'react';

export default function DevelopmentIconRaw(
    {
        strokeWidth = 3,
        className = "w-1",
        stroke = 'currentColor',
        fill = "none"
    }
) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3.5 30 30" className="kdx-detail-slot-icon">
            <g>
                <path
                    d="m13 9 0.59 -0.68a0.38 0.38 0 0 0 0 -0.53 0.37 0.37 0 0 0 -0.52 0 9.53 9.53 0 0 0 -1.43 0.81 2.88 2.88 0 0 0 -0.56 0.6 0.84 0.84 0 0 0 -0.1 0.66 2.08 2.08 0 0 0 0.45 0.74 3.76 3.76 0 0 0 0.65 0.48 7.75 7.75 0 0 0 1 0.44 0.42 0.42 0 0 0 0.43 -0.73 7.45 7.45 0 0 1 -0.6 -0.52 2.44 2.44 0 0 1 -0.4 -0.54 1.83 1.83 0 0 1 0.13 -0.16c0.16 -0.19 0.27 -0.37 0.36 -0.57Z"/>
                <path
                    d="M18.85 10.26a7.31 7.31 0 0 1 -0.59 0.52 0.42 0.42 0 1 0 0.42 0.73 7.75 7.75 0 0 0 1 -0.44 3.76 3.76 0 0 0 0.65 -0.48 2.24 2.24 0 0 0 0.46 -0.74 0.88 0.88 0 0 0 -0.11 -0.66 2.58 2.58 0 0 0 -0.55 -0.6 9.14 9.14 0 0 0 -1.44 -0.81 0.37 0.37 0 0 0 -0.52 0 0.38 0.38 0 0 0 0.06 0.53c0.17 0.21 0.38 0.43 0.58 0.68s0.24 0.34 0.37 0.5l0.12 0.16a2.48 2.48 0 0 1 -0.45 0.61Z"/>
                <path
                    d="M15.93 4a0.42 0.42 0 0 0 -0.41 -0.43c-1.17 -0.1 -2.32 -0.27 -3.47 -0.36 -0.77 -0.07 -1.55 -0.12 -2.34 -0.12a34.83 34.83 0 0 0 -3.92 0.3 0.38 0.38 0 0 0 0 0.75c1.14 0.07 2.25 0.22 3.37 0.32 0.48 0 1 0.06 1.44 0.08s1 0 1.46 0c1.14 0 2.27 -0.08 3.42 -0.14a0.42 0.42 0 0 0 0.45 -0.4Z"/>
                <path
                    d="M18.28 4.78a0.62 0.62 0 0 0 0.23 0 0.54 0.54 0 0 0 0.22 0c0.93 -0.33 0.94 -0.32 0.94 -0.5s-0.11 -0.31 -1.05 -0.58a0.85 0.85 0 0 0 -0.22 0 0.59 0.59 0 0 0 -0.23 0s-0.83 0.35 -0.81 0.65 0.9 0.42 0.92 0.43Z"/>
                <path
                    d="M7.52 8 8 8a4.71 4.71 0 0 0 0.53 0c0.44 0 0.84 -0.11 1.3 -0.15a0.43 0.43 0 0 0 0.45 -0.39 0.42 0.42 0 0 0 -0.34 -0.46c-0.46 -0.12 -0.86 -0.25 -1.29 -0.34a3.94 3.94 0 0 0 -0.58 -0.06 2.46 2.46 0 0 0 -0.6 0 5.73 5.73 0 0 0 -1.32 0.45 0.36 0.36 0 0 0 -0.3 0.43 0.37 0.37 0 0 0 0.43 0.3c0.45 0.06 0.83 0.22 1.24 0.22Z"/>
                <path
                    d="M5.43 11.45a0.36 0.36 0 0 0 0.37 0.37c0.51 0.11 1 0.24 1.5 0.31a3.5 3.5 0 0 0 0.65 0l0.64 0c0.51 -0.06 1 -0.16 1.5 -0.25a0.41 0.41 0 0 0 0.42 -0.42 0.42 0.42 0 0 0 -0.42 -0.42c-0.5 -0.08 -1 -0.19 -1.5 -0.25l-0.64 0a3.5 3.5 0 0 0 -0.65 0c-0.51 0.07 -1 0.2 -1.5 0.31a0.37 0.37 0 0 0 -0.37 0.35Z"/>
                <path
                    d="M15 12.28c0.42 -0.61 0.86 -1.19 1.28 -1.8 0.17 -0.26 0.34 -0.52 0.5 -0.79s0.32 -0.53 0.46 -0.81c0.33 -0.66 0.6 -1.32 0.94 -2a0.38 0.38 0 0 0 -0.08 -0.52 0.37 0.37 0 0 0 -0.52 0.09A18.48 18.48 0 0 0 16 8.16a8.57 8.57 0 0 0 -0.52 0.81c-0.17 0.27 -0.31 0.56 -0.45 0.84 -0.32 0.68 -0.58 1.37 -0.86 2.06a0.43 0.43 0 1 0 0.75 0.41Z"/>
                <g>
                    <path
                        d="M24 20.12a3.24 3.24 0 0 0 -0.26 -0.9 11.33 11.33 0 0 1 -0.43 -1.58c-0.09 -0.37 -0.17 -0.74 -0.28 -1.11 -0.74 -2.32 -0.42 -2.52 -1 -2.44 -1.14 0.12 -2.25 0.27 -3.38 0.37 -0.74 0.06 -1.48 0.11 -2.23 0.11s-1.77 -0.05 -2.66 -0.09 -1.81 -0.1 -2.71 -0.12H9.7a33.49 33.49 0 0 1 -3.7 0.04 13 13 0 0 0 -3.13 0.06c-0.35 0.58 -0.31 0.71 -0.83 1.72 -0.25 0.51 -0.53 1 -0.83 1.5 -0.09 0.14 -0.45 0.56 -0.61 0.85 0 0.07 0.08 -0.31 -0.47 1.39a1.7 1.7 0 0 0 -0.08 0.55 1.58 1.58 0 0 0 0 0.38 2.52 2.52 0 0 0 0.78 1.33 0.42 0.42 0 0 0 0.59 0.1 0.41 0.41 0 0 0 0.1 -0.59 1.88 1.88 0 0 1 -0.11 -0.5 1.86 1.86 0 0 1 0 -0.47l0 -0.48a2.64 2.64 0 0 0 0 -0.28c0.22 0.08 0.42 0.13 0.51 0.16a7.45 7.45 0 0 0 1.7 0.27c0.81 0.05 1.63 0 2.43 0 1.45 0 4.08 0 6.84 -0.08 3.9 -0.07 8.08 -0.24 9.76 -0.34l0 0.26c0 0.09 -0.06 0.41 -0.09 0.61s0 0.42 -0.08 0.63 -0.08 0.21 -0.12 0.32a0.36 0.36 0 0 0 -0.36 -0.07 11.71 11.71 0 0 1 -2.42 0.44 31.69 31.69 0 0 1 -3.77 0.06l-7.67 -0.11c-1.18 -0.05 -2.38 0 -3.56 -0.08a9 9 0 0 1 -2.23 -0.4 0.37 0.37 0 0 0 -0.48 0.21 0.39 0.39 0 0 0 0.22 0.49 10.34 10.34 0 0 0 2.33 0.67c1.18 0.19 2.39 0.25 3.55 0.4s2.1 0.21 3.14 0.24c1.55 0 3.1 0 4.67 -0.09a36.1 36.1 0 0 0 3.87 -0.34c3.79 -0.61 3.7 -0.84 4.12 -1.8a2.7 2.7 0 0 0 0.19 -0.63 2 2 0 0 0 0.04 -0.66Zm-12.84 -1.18c-2.11 0 -4 0 -5.14 0.08 -0.78 0 -1.58 0.12 -2.37 0.12A6.47 6.47 0 0 1 2.2 19a3.15 3.15 0 0 0 -0.69 -0.28c0.17 -0.22 0.44 -0.48 0.49 -0.57 0.27 -0.53 0.52 -1.07 0.76 -1.62s0.38 -0.94 0.56 -1.42a19.76 19.76 0 0 0 2.55 0.33c1.26 0.11 2.53 0.14 3.78 0.22s2.71 0.17 4.06 0.21c0.92 0 1.85 0 2.76 -0.06s1.55 -0.16 2.32 -0.29 1.83 -0.34 2.76 -0.51a11.94 11.94 0 0 0 0 1.57 4.7 4.7 0 0 0 0.43 1.5 6.89 6.89 0 0 0 0.61 1c-1.8 0.02 -7.07 -0.08 -11.43 -0.14Z"/>
                    <path
                        d="M3.43 12.41c-0.06 -1.2 0 -2.42 0 -3.63 0 -0.74 0 -1.47 -0.08 -2.2 0 -0.49 -0.06 -1 -0.1 -1.47s-0.06 -0.8 -0.07 -1.21 0 -0.81 0 -1.21c0 -0.75 0.1 -0.95 0 -1.1l2.69 0.09 4.29 0.13 5.59 -0.08 5.75 -0.13c0 0.53 0.08 1.06 0.09 1.58s0 1.16 0 1.75l0 1.73c0.05 1.74 0.12 3.47 0 5.23a0.44 0.44 0 0 0 0.39 0.47 0.42 0.42 0 0 0 0.52 -0.36c0.22 -1.29 0.41 -2.58 0.51 -3.88 0 -0.56 0.06 -1.12 0 -1.68s0 -1.13 -0.1 -1.69c-0.23 -2.11 -0.83 -4 -1.43 -4L15.71 0.42l-2.24 0c-1.12 0 -2.25 0 -3.37 0.06L5.81 0.67 3 0.83s-0.49 -0.25 -0.76 1.75c-0.08 0.43 -0.13 0.85 -0.17 1.28S2 4.71 2 5.14c0 0.76 -0.06 1.53 -0.06 2.3S2 9 2 9.76a15.33 15.33 0 0 0 0.66 3.86 0.43 0.43 0 0 0 0.83 -0.06c-0.01 -0.48 -0.03 -0.66 -0.06 -1.15Z"/>
                </g>
            </g>
        </svg>
    );
}