import "./App.css"
import React from "react";
import {Home} from "./pages/home/Home";
import {Navigate, Route, Routes} from "react-router-dom";
import {PrivacyAndPolicy} from "./pages/privacy-and-policy/PrivacyAndPolicy";
import {TermsAndConditions} from "./pages/terms-and-conditions/TermsAndConditions";
import {RefundPolicy} from "./pages/refund-policy/RefundPolicy";

function App() {
    return (<Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="privacy-policy" element={<PrivacyAndPolicy/>}/>
        <Route path="terms-and-conditions" element={<TermsAndConditions/>}/>
        <Route path="refund-policy" element={<RefundPolicy/>}/>
        {/*<Route path="checkout" element={<CheckOut/>}/>*/}
        <Route
            path="*"
            element={<Navigate to="/" replace/>}
        />
    </Routes>);
}

export default App;
