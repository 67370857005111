/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 01/02/24
 * Time: 8:50 pm
 * Project: landing_react
 */
import React from 'react';

export default function AdvancedExpertiseIconRaw(
    {
        strokeWidth = 3,
        className = "w-1",
        stroke = 'currentColor',
        fill = "none"
    }
) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3.5 30 30" className="kdx-detail-slot-icon">
            <g>
                <path
                    d="M10.31 3.66a0.3 0.3 0 0 0 -0.31 -0.48c-0.29 0.17 -0.57 0.35 -0.86 0.56 -0.86 0.62 -2 1.49 -1.24 2.23 0.35 0.33 1.83 1.49 2.25 1.45a0.34 0.34 0 0 0 0.34 -0.34c0 -0.28 -0.18 -0.23 -0.51 -0.52a13.77 13.77 0 0 1 -1.16 -1.22c0.11 -0.09 0.25 -0.18 0.28 -0.22a10.18 10.18 0 0 1 1.21 -1.46Z"
                    />
                <path
                    d="M12.19 7.7c0.55 -1.79 1.37 -2.5 1.45 -5.33a0.3 0.3 0 0 0 -0.59 -0.09c-0.38 1.35 -1.24 2.4 -1.52 5.28a0.34 0.34 0 0 0 0.66 0.14Z"
                    />
                <path
                    d="M14.63 6.94c-0.13 0.22 -0.07 0.69 0.49 0.44l0.27 -0.15c0.61 -0.4 1.94 -1.23 2.13 -1.94s-1 -1.43 -2 -2.19a0.29 0.29 0 0 0 -0.42 0c-0.3 0.29 0.13 0.48 1.18 2.07l-0.21 0.21c-1.49 1.77 -1.32 1.31 -1.44 1.56Z"
                    />
                <path
                    d="M5.38 10.23c0.57 0.74 3.13 0.54 4.07 0.49A7.94 7.94 0 0 0 9.12 12c-0.06 0.9 0.65 1.39 1.63 0.86a9.76 9.76 0 0 0 2.34 -2.07 30.32 30.32 0 0 0 6 -0.25 0.91 0.91 0 0 0 0.43 -0.32c0.61 -0.93 0.68 -8.61 0.2 -9.4a1.11 1.11 0 0 0 -0.57 -0.4C17.2 -0.22 8.32 0.07 6.64 0.28a0.34 0.34 0 0 0 -0.3 0.37c0.07 0.64 0.47 0 7.94 0.24a29.38 29.38 0 0 1 4 0.34c0.31 0.06 0.56 0.3 0.64 0.2a48.8 48.8 0 0 1 -0.29 8c-0.49 0 0.91 0.28 -5.18 0.24 -0.42 0 -0.87 -0.06 -1.09 0.24a11.67 11.67 0 0 1 -2.24 2.22c0 -0.08 0.06 -0.17 0.09 -0.28 0.11 -0.62 0.46 -1.33 0.2 -1.87 -0.18 -0.36 -0.52 -0.31 -0.91 -0.28s-3.07 0.27 -3.41 0c-0.36 -1.8 -0.16 -8.31 -0.18 -8.45A0.3 0.3 0 0 0 5.75 1s-0.44 0 -0.46 0.33c-0.04 0.78 -0.42 8.26 0.09 8.9Z"
                    />
                <path
                    d="M7 14.38c-0.45 -0.54 -1.59 -1.19 -1.73 -0.56a0.35 0.35 0 0 0 0.25 0.41c2 0.48 1.6 4.71 -1.74 3.88 -2.08 -0.46 -1.87 -4.45 1.08 -4.22a0.3 0.3 0 0 0 0.06 -0.6A3.08 3.08 0 0 0 2.84 19a3.69 3.69 0 0 0 -2.33 1.2A2 2 0 0 0 0 21.8a0.3 0.3 0 0 0 0.6 0c0.07 -0.52 0.79 -1 1.24 -1.29a6.7 6.7 0 0 1 1.49 -1 0.33 0.33 0 0 0 0.24 -0.29A3.12 3.12 0 0 0 7 14.38Z"
                    />
                <path
                    d="M15.61 21a7 7 0 0 0 -1.9 -0.65c2.4 -1.35 2.23 -5.57 -0.49 -6.1a0.34 0.34 0 0 0 -0.15 0.66c2.36 0.61 1.91 5.61 -2.05 4.63 -2.54 -0.56 -2.21 -5.3 1.28 -5a0.3 0.3 0 0 0 0.06 -0.59 3.49 3.49 0 0 0 -2.21 6.51 6.23 6.23 0 0 0 -1.84 0.54 2.74 2.74 0 0 0 -1.58 2.64 0.3 0.3 0 0 0 0.6 0c0 -0.49 0.5 -0.84 0.84 -1.12 2.1 -1.73 2.68 -1.42 2.58 -1.92a4.78 4.78 0 0 0 2.42 -0.06c-0.08 0.52 0.38 0.1 2.58 1.91 0.33 0.27 0.79 0.61 0.85 1.12a0.3 0.3 0 0 0 0.59 0A2.76 2.76 0 0 0 15.61 21Z"
                    />
                <path
                    d="M23.49 20.17c-0.26 -0.33 -0.67 -0.87 -2.4 -1.26a3.25 3.25 0 0 0 0.74 -4.5c-0.43 -0.52 -1.58 -1.19 -1.73 -0.55a0.33 0.33 0 0 0 0.26 0.4c2 0.53 1.55 4.7 -1.75 3.89 -2.08 -0.46 -1.87 -4.46 1.09 -4.23a0.3 0.3 0 0 0 0.06 -0.59 3.1 3.1 0 0 0 -3.25 4.44 3.19 3.19 0 0 0 3.89 1.45c0.06 0.37 0.31 0.08 2.23 1.62 0.26 0.21 0.69 0.5 0.74 0.91a0.3 0.3 0 0 0 0.6 0 2 2 0 0 0 -0.48 -1.58Z"
                    />
            </g>
        </svg>
    );
}