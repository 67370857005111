/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 01/02/24
 * Time: 9:10 pm
 * Project: landing_react
 */
import React from 'react';

export default function LaunchIconRaw(
    {
        strokeWidth = 3,
        className = "w-1",
        stroke = 'currentColor',
        fill = "none"
    }
) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3.5 30 30" className="kdx-detail-slot-icon">
            <g>
                <path
                    d="M24 21.19c-0.14 -1 -1.42 -2.08 -2.05 -2.52l-0.67 -0.47c-0.68 -0.51 -0.43 -0.74 -0.83 -0.74 0 -1 0.13 -1.93 0.13 -2.8l0 -1.2c0 -0.39 -0.08 -0.8 -0.14 -1.2 -0.14 -0.87 -0.33 -1.75 -0.57 -2.77a0.39 0.39 0 0 0 -0.44 -0.33 0.38 0.38 0 0 0 -0.32 0.44c0.07 1.06 0.07 2 0.11 2.85 0 0.64 0 1.26 0.11 1.89 0.11 1 0.3 2 0.48 3.14l-1.93 0c-1.2 -0.05 -2.41 -0.18 -3.61 -0.26 -0.82 -0.05 -1.63 -0.08 -2.44 -0.1 -1 0 -1.95 0 -2.92 0.11 -1.45 0.11 -2.88 0.3 -4.33 0.48a0.34 0.34 0 1 0 0 0.68l2.88 0c1.43 0 2.85 0 4.29 0.08 0.86 0 1.72 0.05 2.59 0.07s1.74 0 2.61 0c1 0 2.06 -0.08 3.07 -0.18l0.75 0.59 0.67 0.52a6.61 6.61 0 0 1 1 0.89L18.31 20l-4.08 0c-2 0 -4.07 0.12 -6.1 0.12 -1.68 0.06 -3.38 0.29 -5.07 0.35l-1.8 0A5.05 5.05 0 0 1 2 19.2l0.68 -0.61 1 -0.66a0.34 0.34 0 0 0 0.14 -0.46 0.34 0.34 0 0 0 -0.36 -0.15l0.14 -1.84c0.07 -0.91 0.18 -1.81 0.25 -2.73S4 10.93 4 10c-0.11 -2.34 -0.2 -1.24 1 -1.44l2.37 -0.43a0.39 0.39 0 0 0 -0.16 -0.76l-2.31 0.2c-0.18 0 -1 -0.07 -1.4 0a0.78 0.78 0 0 0 -0.53 0.3 1.5 1.5 0 0 0 -0.2 0.61c-0.07 0.48 0 1.23 0 1.5 0 0.92 -0.1 1.84 -0.11 2.75s0 1.84 0.07 2.76l0.09 2.23 -0.49 0.3 -0.81 0.66A5.58 5.58 0 0 0 0.08 21a1.74 1.74 0 0 0 0.63 2 2.8 2.8 0 0 0 1.11 0.43 8.94 8.94 0 0 0 1.54 0.08l3.28 0.14c2.86 0 5.72 0.17 8.57 0.18 1.24 0 2.48 0 3.72 -0.13l2.07 -0.32 1.28 -0.33 0.85 -0.36a1.37 1.37 0 0 0 0.87 -1.5Zm-1.32 0.61 -0.7 0.26 -1.17 0.26 -1.92 0.18 -2.43 0c-3.27 0 -6.54 -0.2 -9.81 -0.17h-3.3a7.94 7.94 0 0 1 -1.38 0 2 2 0 0 1 -0.71 -0.23 0.85 0.85 0 0 1 -0.3 -1s0 -0.05 0 -0.07a18 18 0 0 0 2.08 0.18c1.68 0.06 3.39 0 5.06 0l4.1 0c2 -0.05 4.07 -0.19 6.1 -0.26h4.57l0.06 0a0.53 0.53 0 0 1 -0.27 0.85Z"/>
                <path
                    d="M9.41 7a7.34 7.34 0 0 0 1.13 0.86 0.39 0.39 0 1 0 0.51 -0.59 7 7 0 0 1 -0.75 -0.78 0.87 0.87 0 0 1 -0.18 -0.42l0.17 -0.26a2.1 2.1 0 0 1 0.71 -0.67 3.56 3.56 0 0 1 1.07 -0.42l0.43 -0.07s-0.08 0.1 -0.11 0.16a9 9 0 0 0 -1 2.48 2 2 0 0 0 0.5 1.92l0.77 0.79a2 2 0 0 0 2 0.52 9.17 9.17 0 0 0 2.49 -1.08c0.08 0 0.15 -0.11 0.23 -0.15l-0.09 0.51a3.56 3.56 0 0 1 -0.42 1.07 2.2 2.2 0 0 1 -0.69 0.75l-0.26 0.17a0.87 0.87 0 0 1 -0.42 -0.18 8 8 0 0 1 -0.78 -0.75 0.39 0.39 0 0 0 -0.59 0.51 7.34 7.34 0 0 0 0.86 1.13 1.64 1.64 0 0 0 0.7 0.4 1.32 1.32 0 0 0 0.56 0 2 2 0 0 0 0.64 -0.25A3 3 0 0 0 18 11.49a4 4 0 0 0 0.24 -1.49l-0.08 -1a1.1 1.1 0 0 1 0 -0.18 9.45 9.45 0 0 0 1.78 -1.62 7.64 7.64 0 0 0 1.59 -3.42 6.09 6.09 0 0 0 0 -1.66A2.18 2.18 0 0 0 21 0.76a2.21 2.21 0 0 0 -1.26 -0.53 6.54 6.54 0 0 0 -1.64 0.09A7.74 7.74 0 0 0 14.74 2a9.74 9.74 0 0 0 -1.63 1.82H13l-1 -0.12a4.07 4.07 0 0 0 -1.56 0.3 2.89 2.89 0 0 0 -1.2 1.14 2 2 0 0 0 -0.24 0.62 1.27 1.27 0 0 0 0 0.55 1.74 1.74 0 0 0 0.41 0.69Zm6 -4.16a6.67 6.67 0 0 1 2.9 -1.28 5.07 5.07 0 0 1 1.06 -0.07 1.14 1.14 0 0 1 0.7 0.17 1.15 1.15 0 0 1 0.18 0.73 4.9 4.9 0 0 1 -0.05 1.09A6.35 6.35 0 0 1 19 6.4a8.92 8.92 0 0 1 -2.42 2.2 8.36 8.36 0 0 1 -2.18 1.07 1.22 1.22 0 0 1 -1.2 -0.21l-0.81 -0.77a1.28 1.28 0 0 1 -0.22 -1.26 8 8 0 0 1 1.09 -2.17 9.15 9.15 0 0 1 2.18 -2.4Z"/>
                <path
                    d="M8.16 10.88a1.15 1.15 0 0 0 0.31 0.16l-0.08 0.11a7.83 7.83 0 0 0 -1 1.55 1.87 1.87 0 0 0 -0.08 1.11 0.65 0.65 0 0 0 0.17 0.27 0.69 0.69 0 0 0 0.27 0.17A1.87 1.87 0 0 0 9 14.21a7.73 7.73 0 0 0 1.54 -1l0.11 -0.08a1.37 1.37 0 0 0 0.16 0.32 0.91 0.91 0 0 0 1.18 0.43 1.86 1.86 0 0 0 0.9 -1.05 2.45 2.45 0 0 0 0.12 -1 10.26 10.26 0 0 0 -0.19 -1.29 0.37 0.37 0 0 0 -0.44 -0.32 0.38 0.38 0 0 0 -0.33 0.43 9.12 9.12 0 0 1 0.09 1.19 1.75 1.75 0 0 1 -0.12 0.65c0 0.09 -0.22 0.17 -0.32 0.26l-0.05 -0.66a0.64 0.64 0 0 0 -0.79 -0.44l-0.28 0.1 -0.73 0.42c-0.24 0.15 -0.59 0.4 -1 0.6l-0.05 0a10 10 0 0 1 0.61 -1l0.41 -0.75a1.79 1.79 0 0 0 0.1 -0.27 0.29 0.29 0 0 0 0 -0.09 0.56 0.56 0 0 0 -0.47 -0.7l-0.72 0.04s0 -0.05 -0.06 0 0 0.1 0 0.09 0.27 -0.33 0.42 -0.39a1.81 1.81 0 0 1 0.66 -0.14 9.14 9.14 0 0 1 1.19 0.08 0.34 0.34 0 0 0 0.39 -0.29 0.35 0.35 0 0 0 -0.33 -0.43 12.25 12.25 0 0 0 -1.28 -0.2 2.67 2.67 0 0 0 -1 0.11 1.91 1.91 0 0 0 -1 0.88 0.91 0.91 0 0 0 0.44 1.17Z"/>
            </g>
        </svg>
    );
}