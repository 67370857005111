/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 30/01/24
 * Time: 1:20 am
 * Project: landing_react
 */
import React, {memo} from 'react';

export const IconDisplayCard = memo(({data}) => {
    const {icon: Icon, title, description} = data
    return (
        <div>
            <div className="kdx-detail-slot-container">
                <div className="kdx-detail-slot-icon-container_l1">
                    <div className="kdx-detail-slot-icon-container_l2"/>
                    <div className="kdx-detail-slot-icon-holder">
                        <div className="kdx-detail-slot-icon-holder_l1"/>
                        <div className="kdx-detail-slot-icon-holder_l2">
                            <div className="kdx-detail-slot-icon-holder_l3"/>
                            <Icon className="kdx-detail-slot-icon"/>
                        </div>
                    </div>
                </div>
                <h3 className="kdx-detail-heading">
                    <span>{title}</span>
                </h3>
            </div>
            <p className="kdx-detail-sub-heading">
                {/*<p>Package starting from {price}</p>*/}
                  <span>
                    {description}
                  </span>
            </p>
        </div>
    );
})