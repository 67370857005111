/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 01/02/24
 * Time: 9:01 pm
 * Project: landing_react
 */
import React from 'react';

export default function WireframeIconRaw(
    {
        strokeWidth = 3,
        className = "w-1",
        stroke = 'currentColor',
        fill = "none"
    }
) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3.5 30 30" className="kdx-detail-slot-icon">
            <g>
                <path
                    d="M19.78 8.55a3.28 3.28 0 0 0 -0.89 0.93c-0.07 0.08 -0.13 0.61 0 0.76l3 2.62L15.38 20a4.75 4.75 0 0 0 -2.5 -2.32c1.48 -1.87 4.06 -4.9 5.59 -6.69a0.3 0.3 0 0 0 -0.48 -0.36c-4.82 5.23 -7 7.52 -7 8.09 -0.05 1 -0.68 3.43 -0.21 3.91 0.66 0.67 3.58 -0.14 4.13 -0.48s7.07 -7.94 7.73 -8.8c0.84 -1.1 1.34 -1.66 1.39 -2.72a2.71 2.71 0 0 0 -4.25 -2.08Zm-6.69 12.94a11 11 0 0 1 -1.37 0.05c0.06 -0.82 0.23 -2.19 0.27 -2.68 0.08 0 0.39 -0.56 0.43 -0.64a11 11 0 0 0 1.5 1.49c0.33 0.28 0.66 0.53 0.94 0.8 -0.64 0.69 -0.48 0.67 -1.77 0.98Zm9.33 -9.21c-0.53 -0.49 -2.46 -2 -3 -2.5a1.9 1.9 0 0 1 2.85 -0.18 1.63 1.63 0 0 1 0.71 1 2.26 2.26 0 0 1 -0.56 1.68Z"
                />
                <path
                    d="M8.79 20.67c-2.59 0 -6.79 0.42 -7.82 0.1 0 -2.54 0.09 -14.2 0.44 -17.27 0.05 -0.37 0.26 -0.71 0.18 -0.81C3 2 16.42 2.15 19.13 2.4c0.16 0.59 0.33 3.07 0.42 4.06a0.34 0.34 0 0 0 0.68 -0.06C20 2.78 20 2 19.61 1.72S2.16 0.49 0.69 1.94C-0.43 3.07 0.15 19.13 0.13 21c0 1.2 4.86 0.37 8.68 0.24a0.3 0.3 0 0 0 -0.02 -0.57Z"
                />
                <path
                    d="M8.65 13.61a40.3 40.3 0 0 0 -5.07 -0.32c-0.56 0 -1.09 0.05 -1.1 0.62 -0.29 7 -0.53 5.12 5.16 5a0.34 0.34 0 0 0 0 -0.68l-1.85 0c-0.93 0 -2.17 0.13 -2.35 0.08 0 -0.44 0.19 -4 0.18 -3.88 1.26 -0.07 4.36 -0.17 4.86 -0.07 0.2 0 0.87 1.17 1 1.36a0.3 0.3 0 1 0 0.53 -0.28c-0.29 -0.54 -0.81 -1.68 -1.36 -1.83Z"
                />
                <path
                    d="M13.86 7.32a6 6 0 0 1 1.35 -0.48 0.56 0.56 0 0 1 0.44 0.08C15.88 7.06 17 8 17.07 8l0 0.46a0.3 0.3 0 1 0 0.6 0 16.62 16.62 0 0 0 0 -3.76c-0.53 -0.82 -2.14 -0.74 -2.84 -0.86 -2.56 -0.34 -11.59 -0.13 -11.69 -0.1 -0.66 0.21 -0.64 0.44 -0.66 0.87a42.76 42.76 0 0 0 -0.16 5.23 2.18 2.18 0 0 0 0.89 1.72c1.26 0.59 10.44 0.18 11.82 0.11A0.34 0.34 0 0 0 15 11c-0.1 0 -11 -0.32 -11.06 -0.34C5.7 9.13 6.73 7.21 8.72 8.58a13 13 0 0 1 1.86 1.71 2.86 2.86 0 0 0 1 0.61 0.35 0.35 0 0 0 0.19 -0.63c-0.53 -0.48 -0.28 0 -1.09 -1.12 0.32 -0.15 2.38 -1.44 3.18 -1.83Zm-3.6 1.3c-3.51 -4.19 -5.8 0.74 -6.89 1.26C3.11 8.83 3.2 8 3.08 4.5c3.09 0 3.23 0.12 7.32 0.27 5.13 0.19 6.14 0.11 6.48 0.37A16.55 16.55 0 0 1 17 7c-0.68 -0.63 -1 -1.27 -2 -1.19a6.52 6.52 0 0 0 -3.67 1.89c-0.15 0.13 -0.72 0.6 -1.07 0.92Z"
                />
            </g>
        </svg>
    );
}