/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 30/01/24
 * Time: 1:27 am
 * Project: landing_react
 */
import React from 'react';

export default function WebDevIconRaw() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3.5 30 30" className="kdx-detail-slot-icon">
            <g>
                <path
                    d="M3.2 6.87a0.39 0.39 0 0 0 -0.12 -0.53c-0.36 -0.29 -0.71 -0.56 -1 -0.87a4.53 4.53 0 0 1 -0.54 -0.62l-0.12 -0.13 0.82 -0.65c0.58 -0.48 1.12 -1 1.65 -1.52a0.35 0.35 0 0 0 0.05 -0.48 0.33 0.33 0 0 0 -0.47 0c-0.73 0.45 -1.51 0.81 -2.24 1.28 -0.35 0.15 -0.65 0.39 -0.95 0.65a0.75 0.75 0 0 0 -0.23 0.86 3.28 3.28 0 0 0 0.56 0.81 5.35 5.35 0 0 0 0.81 0.63c0.4 0.26 0.82 0.48 1.25 0.71a0.39 0.39 0 0 0 0.53 -0.14Z"/>
                <path
                    d="M5.62 7.75a0.4 0.4 0 0 0 0.49 -0.25c0.33 -0.77 0.68 -1.52 1 -2.31 0.13 -0.33 0.26 -0.66 0.37 -1s0.22 -0.68 0.31 -1c0.21 -0.81 0.36 -1.62 0.56 -2.44A0.33 0.33 0 0 0 8.14 0.3a0.33 0.33 0 0 0 -0.43 0.2c-0.38 0.75 -0.77 1.49 -1.1 2.28 -0.14 0.33 -0.26 0.67 -0.37 1s-0.2 0.68 -0.29 1c-0.21 0.81 -0.37 1.63 -0.57 2.44a0.4 0.4 0 0 0 0.24 0.53Z"/>
                <path
                    d="M9.31 7.1c0.76 -0.36 2.57 -1.08 3.32 -1.48a3.13 3.13 0 0 0 0.42 -0.27 0.66 0.66 0 0 0 0.22 -0.59 1.51 1.51 0 0 0 -0.64 -0.82 16.07 16.07 0 0 0 -2.1 -1.18l-0.74 -0.28a0.33 0.33 0 0 0 -0.44 0.17 0.33 0.33 0 0 0 0.16 0.44l0.64 0.4c0.11 0.07 0.92 0.73 1.49 1.24 -0.8 0.5 -2.11 1.31 -2.71 1.71a0.38 0.38 0 0 0 -0.14 0.56 0.38 0.38 0 0 0 0.52 0.1Z"/>
                <path
                    d="M23 22.3c-3.23 0.1 -6 -0.05 -9 0 -0.74 0 -1.5 0 -2.28 0.08a46.84 46.84 0 0 1 -4.77 0 21.83 21.83 0 0 1 -2.68 -0.28c0 -0.64 -0.06 -1.7 -0.06 -1.8 -0.1 -1.87 -0.31 -5 -0.41 -7.72l0 -3.29A0.33 0.33 0 0 0 3.46 9a0.32 0.32 0 0 0 -0.36 0.31C3 10.42 3 12 3 13.73l0 6.64 0 2.14a0.88 0.88 0 0 0 0.54 0.78 19.82 19.82 0 0 0 3.32 0.41 43.21 43.21 0 0 0 4.91 -0.13c1.17 -0.11 2.28 -0.17 3.36 -0.21 2.61 -0.1 5.08 -0.1 7.91 -0.29a0.38 0.38 0 0 0 0.36 -0.4 0.39 0.39 0 0 0 -0.4 -0.37Z"/>
                <path
                    d="M23.82 9.45a11.51 11.51 0 0 0 -0.3 -2.86 3.26 3.26 0 0 0 -1.23 -1.82A2.71 2.71 0 0 0 21 4.34a13.32 13.32 0 0 0 -1.68 0c-1.09 0 -2.14 0 -3.21 0.07a18.24 18.24 0 0 0 -2.17 0.2 0.34 0.34 0 0 0 -0.28 0.39 0.33 0.33 0 0 0 0.38 0.28 18.15 18.15 0 0 1 2.08 -0.07c1.06 0 2.11 0.13 3.2 0.17a11.37 11.37 0 0 1 1.53 0.06 1.56 1.56 0 0 1 0.72 0.28 2.07 2.07 0 0 1 0.7 1.19 5.21 5.21 0 0 1 0.09 0.57l-3.85 0c-1 0 -2 0 -3 0.07 -1.92 0.1 -3.72 0.29 -4.94 0.35a0.34 0.34 0 1 0 0 0.67c1.09 0.09 2.66 0.21 4.36 0.25l1.87 0c0.63 0 1.25 -0.06 1.86 -0.1 1.44 -0.11 2.76 -0.27 3.79 -0.42l0 1.17c0.16 2.64 0.38 5.31 0.52 8 0.06 1.14 0.11 2.27 0.12 3.41a0.38 0.38 0 0 0 0.38 0.38 0.37 0.37 0 0 0 0.39 -0.37c0.06 -1.52 0.07 -3.06 0 -4.59 0.14 -2.3 0 -4.58 -0.04 -6.85Z"/>
                <path
                    d="m6.52 11.37 -0.1 0a0.34 0.34 0 0 0 -0.3 0.37c0 1.06 -0.08 2.1 -0.08 3.16 0 0.63 0 1.25 0.08 1.88 0 0.94 0.13 1.88 0.15 2.82a0.38 0.38 0 0 0 0.34 0.4 0.37 0.37 0 0 0 0.26 0.15l1.58 0.08a5.56 5.56 0 0 0 1.93 -0.36 1.72 1.72 0 0 0 0.83 -0.73 1.61 1.61 0 0 0 0.1 -0.35 8 8 0 0 0 0 -0.8c0.05 -0.42 0.07 -0.83 0.08 -1.25l0 -1.82c0 -0.61 0 -1 -0.05 -1.49a6.13 6.13 0 0 0 -0.17 -1.07 3.16 3.16 0 0 0 -0.21 -0.67 1.09 1.09 0 0 0 -0.3 -0.41 0.82 0.82 0 0 0 -0.48 -0.15c-0.24 0 -0.64 0.05 -0.74 0.05l-2.76 0.17c-0.06 -0.04 -0.11 0 -0.16 0.02Zm3.51 0.86 0 0.34a6.54 6.54 0 0 1 0.06 0.89l0 1.47 0.07 2.94 0 0.76a1.08 1.08 0 0 1 -0.67 0.44 5.23 5.23 0 0 1 -1.13 0.24l-1.34 0c0.12 -0.84 0.23 -1.68 0.27 -2.53l0 -1.2a12.09 12.09 0 0 0 -0.08 -1.21C7.18 13.6 7 12.8 6.85 12l2.56 0.12a2.79 2.79 0 0 0 0.59 0.11Z"/>
                <path
                    d="m21.5 12.71 -0.11 -0.71 -0.12 -0.38a1.17 1.17 0 0 0 -0.86 -0.73 5.49 5.49 0 0 0 -1.35 0L17 11l-3.43 0.22a0.3 0.3 0 0 0 -0.32 0.27 0.34 0.34 0 0 0 -0.07 0.15c-0.05 0.39 -0.11 0.76 -0.14 1.14a6.91 6.91 0 0 0 0 0.79 1.75 1.75 0 0 0 0.13 0.65 0.86 0.86 0 0 0 0.26 0.34 0.5 0.5 0 0 0 0.22 0.1 3 3 0 0 0 0.47 0l1.32 0.12c0.42 0 0.83 0 1.25 0.06l1.84 0a8.34 8.34 0 0 0 1.65 -0.18 3.35 3.35 0 0 0 1.11 -0.47 0.69 0.69 0 0 0 0.26 -0.39 5.38 5.38 0 0 0 -0.05 -1.09Zm-1.73 1a7.31 7.31 0 0 1 -1.32 0.15c-0.6 0 -1.19 0.07 -1.79 0.09h-1.17l-1.3 -0.06 -0.18 0a0.43 0.43 0 0 0 0 -0.05 1.58 1.58 0 0 1 -0.07 -0.34 5.81 5.81 0 0 1 0 -0.72l0 -1.06h5.16a8.66 8.66 0 0 1 0.87 0c0.08 0 0.09 0.07 0.13 0.17l0.07 0.28 0.06 0.61c0 0.55 0.57 0.68 -0.46 0.94Z"/>
                <path
                    d="m21.5 18.11 -0.11 -0.76 -0.12 -0.35a1.2 1.2 0 0 0 -0.86 -0.74 5.09 5.09 0 0 0 -1.35 0l-2.06 0.11 -3.43 0.21c-0.18 0 -0.33 0.13 -0.32 0.27a0.26 0.26 0 0 0 -0.07 0.15c-0.05 0.38 -0.11 0.76 -0.14 1.13a6.94 6.94 0 0 0 0 0.79 1.75 1.75 0 0 0 0.13 0.65 0.86 0.86 0 0 0 0.26 0.34 0.64 0.64 0 0 0 0.22 0.11 4.7 4.7 0 0 0 0.47 0l1.32 0.12c0.42 0 0.83 0 1.25 0.06l1.84 0a8.32 8.32 0 0 0 1.65 -0.17 3.37 3.37 0 0 0 1.11 -0.48 0.66 0.66 0 0 0 0.26 -0.38 5.35 5.35 0 0 0 -0.05 -1.06Zm-1.73 1a6.53 6.53 0 0 1 -1.32 0.16c-0.6 0 -1.19 0.07 -1.79 0.08h-1.17l-1.3 -0.05 -0.18 0 0 -0.05a1.58 1.58 0 0 1 -0.07 -0.34 5.78 5.78 0 0 1 0 -0.71l0 -1.07h5.16l0.87 0c0.08 0 0.09 0.08 0.13 0.17l0.07 0.28 0.06 0.61c0 0.53 0.57 0.66 -0.46 0.92Z"/>
            </g>
        </svg>
    );
}