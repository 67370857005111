/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 01/02/24
 * Time: 9:08 pm
 * Project: landing_react
 */
import React from 'react';

export default function RatingIconRaw(
    {
        strokeWidth = 3,
        className = "w-1",
        stroke = 'currentColor',
        fill = "none"
    }
) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3.5 30 30" className="kdx-detail-slot-icon">
            <g>
                <path
                    d="M19.62 0.77c-3 -1.38 -4.65 -0.59 -6 0.35a7 7 0 0 0 -2.51 3.26 6.21 6.21 0 0 0 0.93 5.7 5.9 5.9 0 0 0 2.27 1.78l0 0.69c0 0.6 0.08 1.2 0.1 1.79v2.12l0 0.82a1.87 1.87 0 0 0 0.07 0.49 0.49 0.49 0 0 0 0.34 0.32 0.55 0.55 0 0 0 0.35 0c0.09 0 0.64 -0.54 0.77 -0.65l1.3 -1.68 0.1 -0.14 1.34 1.51 0.39 0.43a0.15 0.15 0 0 1 0 -0.05s0.38 0.38 0.51 0.38a0.53 0.53 0 0 0 0.36 -0.13 1.44 1.44 0 0 0 0.37 -0.74 6.79 6.79 0 0 0 0 -1.34l0 -4.12a6 6 0 0 0 2.56 -4.12A6.1 6.1 0 0 0 19.62 0.77Zm-0.18 14.88 0 0.76c-0.31 -0.36 -0.58 -0.74 -0.88 -1.09 -0.15 -0.17 -0.3 -0.34 -0.46 -0.49a3.59 3.59 0 0 0 -0.55 -0.45 0.58 0.58 0 0 0 -0.6 0 0.89 0.89 0 0 0 -0.2 0.19c-0.12 0.14 -0.24 0.36 -0.33 0.47l-1.06 1.44v-0.07l-0.05 -0.76 -0.11 -1.39c-0.06 -0.51 -0.14 -1 -0.2 -1.53 0 -0.23 0 -0.45 -0.05 -0.67a7.06 7.06 0 0 0 2.48 0.35 5.87 5.87 0 0 0 2.25 -0.55ZM22 7.24a4.77 4.77 0 0 1 -4.58 4.14A5.26 5.26 0 0 1 12 4.62a6.23 6.23 0 0 1 2.14 -2.95C15 1 18.15 0.86 19.3 1.44a5.28 5.28 0 0 1 2.7 5.8Z"/>
                <path
                    d="M18.08 4.09a7.13 7.13 0 0 0 -0.31 -0.7 2.69 2.69 0 0 0 -0.34 -0.47 1.93 1.93 0 0 0 -0.35 -0.28 0.59 0.59 0 0 0 -0.42 -0.05 0.6 0.6 0 0 0 -0.39 0.33c-0.08 0.16 -0.22 0.6 -0.24 0.62 -0.13 0.32 -0.25 0.63 -0.37 1s-0.32 1 -0.48 1.43 -0.31 1 -0.44 1.53c-0.09 0.35 -0.17 0.69 -0.23 1a0.28 0.28 0 0 0 0.54 0.12c0.09 -0.33 0.2 -0.65 0.32 -1s0.18 -0.41 0.27 -0.62a6 6 0 0 0 0.94 0.11l0.66 0c0.22 0 0.49 -0.09 0.73 -0.15l0.21 -0.07 0.6 1.47a0.32 0.32 0 0 0 0.4 0.19 0.33 0.33 0 0 0 0.2 -0.4c-0.25 -0.81 -0.49 -1.62 -0.74 -2.42 -0.18 -0.57 -0.36 -1.11 -0.56 -1.64Zm-0.91 2a5.35 5.35 0 0 0 -0.7 0.08c-0.18 0 -0.35 0.11 -0.51 0.17a0.64 0.64 0 0 0 0 -0.07l1 -2.14c0.07 0.13 0.14 0.27 0.2 0.4 0.24 0.5 0.46 1 0.68 1.52l0 0.1Z"/>
                <path
                    d="M5.56 6.94 6 6.89l0.47 -0.11c0.37 -0.1 0.71 -0.22 1.09 -0.31a0.32 0.32 0 0 0 0.29 -0.35 0.31 0.31 0 0 0 -0.34 -0.28c-0.41 0 -0.79 -0.06 -1.19 0l-0.49 0a4.31 4.31 0 0 0 -0.49 0.11c-0.37 0.12 -0.71 0.28 -1.08 0.43a0.27 0.27 0 0 0 -0.23 0.32 0.29 0.29 0 0 0 0.32 0.23c0.44 0 0.82 0.07 1.21 0.01Z"/>
                <path
                    d="M4.37 11a5.31 5.31 0 0 0 1.48 0.34 5.21 5.21 0 0 0 0.88 -0.06c0.43 -0.06 0.85 -0.18 1.27 -0.26a0.31 0.31 0 0 0 0.3 -0.32 0.32 0.32 0 0 0 -0.3 -0.36c-0.41 0 -0.83 -0.09 -1.27 -0.11a4.47 4.47 0 0 0 -0.52 0 4.55 4.55 0 0 0 -0.52 0c-0.41 0 -0.8 0.13 -1.2 0.14a0.28 0.28 0 0 0 -0.32 0.24 0.27 0.27 0 0 0 0.2 0.39Z"/>
                <path
                    d="M11.32 14.67c-0.81 0 -1.61 -0.09 -2.41 -0.11h-1l-1 0c-0.8 0.05 -1.58 0.15 -2.39 0.19a0.28 0.28 0 0 0 -0.29 0.26 0.27 0.27 0 0 0 0.26 0.29c0.82 0.12 1.61 0.23 2.42 0.27l1 0c0.3 0 0.69 0 1 -0.06 0.8 -0.07 1.6 -0.17 2.4 -0.26a0.31 0.31 0 0 0 0.31 -0.32 0.32 0.32 0 0 0 -0.3 -0.26Z"/>
                <path
                    d="m10.37 19.4 -1.61 -0.1 -1.72 0a22.4 22.4 0 0 0 -2.61 0.25 0.28 0.28 0 1 0 0 0.55c0.85 0 1.7 0.17 2.57 0.23l1.73 0c0.56 0 1.25 -0.13 1.87 -0.16a6.57 6.57 0 0 1 0.77 0 0.33 0.33 0 0 0 0.36 -0.27 0.32 0.32 0 0 0 -0.27 -0.36 8 8 0 0 0 -1.09 -0.14Z"/>
                <path
                    d="M18 18.5a0.28 0.28 0 0 0 -0.25 0.3c-0.05 0.51 -0.16 1 -0.21 1.5l0 1 0 1c0.06 0.52 -0.23 0.53 -2.5 0.71 -1.28 0.11 -2.58 0.1 -3.89 0.09s-2.51 0 -3.76 -0.06c-0.83 0 -1.65 -0.07 -2.49 -0.14 -0.4 0 -0.92 -0.05 -1.4 -0.14a1.49 1.49 0 0 1 -0.66 -0.27c-0.08 -0.06 -0.09 -0.19 -0.12 -0.32s-0.07 -0.47 -0.1 -0.65c-0.12 -0.74 -0.23 -1.49 -0.32 -2.23 0 -0.5 -0.1 -1 -0.13 -1.49 0 -0.68 -0.06 -1.36 -0.07 -2l0 -4.83 -0.17 -8c1 0.09 1.93 0.17 2.9 0.19l1.66 0 1.65 -0.11c0.59 -0.05 1.22 -0.15 1.84 -0.19a6.31 6.31 0 0 1 0.75 0 0.31 0.31 0 0 0 0.27 -0.41 0.31 0.31 0 0 0 -0.28 -0.35c-0.26 0 -0.54 -0.06 -0.82 -0.08C9.31 2 8.66 2 8.07 2L5.42 2c-1.33 0.06 -2.64 0.23 -4 0.27 -0.31 0 -0.26 0.19 -0.27 8.64 0 1.38 -0.12 2.76 -0.15 4.15l0 2.79c0 0.64 0.1 1.29 0.18 1.93s0.18 1.27 0.28 1.9a5.14 5.14 0 0 0 0.22 1.06 1.31 1.31 0 0 0 0.39 0.56 2.4 2.4 0 0 0 0.85 0.41 9.61 9.61 0 0 0 1.79 0.18c0.86 0.06 1.7 0.08 2.55 0.08 1.26 0 2.51 0 3.79 -0.06s2.63 -0.08 3.94 -0.2c3.42 -0.34 3 -0.3 3.42 -2.36a5.23 5.23 0 0 0 0.06 -1 9.06 9.06 0 0 0 -0.28 -1.54 0.28 0.28 0 0 0 -0.19 -0.31Z"/>
            </g>
        </svg>
    );
}