/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 01/02/24
 * Time: 8:42 pm
 * Project: landing_react
 */
import React from 'react';

export default function MoneySaving() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="kdx-detail-slot-icon" viewBox="-3 -3.5 30 30" >
            <g>
                <path
                    d="M16.69 7.12c0 0.23 -0.08 0.45 -0.11 0.68a0.39 0.39 0 0 0 0.3 0.46 0.38 0.38 0 0 0 0.47 -0.3c0.06 -0.2 0.14 -0.41 0.21 -0.62a3.88 3.88 0 0 0 0.75 0.07 2 2 0 0 0 2.11 -2 1.45 1.45 0 0 0 -0.73 -1.31 2.87 2.87 0 0 0 -1 -0.36c0.05 -0.19 0.11 -0.38 0.15 -0.58s0.16 -0.38 0.16 -0.56a3 3 0 0 1 0.84 0.46 0.34 0.34 0 1 0 0.46 -0.51 4.05 4.05 0 0 0 -1.11 -0.74c0.1 -0.44 0.19 -0.9 0.31 -1.34a0.35 0.35 0 0 0 -0.18 -0.47 0.34 0.34 0 0 0 -0.45 0.2c-0.21 0.42 -0.39 0.85 -0.6 1.28a2.94 2.94 0 0 0 -0.71 -0.09A1.63 1.63 0 0 0 15.79 3a1.4 1.4 0 0 0 0.57 1.36 2.42 2.42 0 0 0 0.76 0.33c0 0.13 -0.07 0.26 -0.1 0.39 -0.08 0.39 -0.12 0.76 -0.18 1.15a2.48 2.48 0 0 1 -1.37 -0.87 0.4 0.4 0 0 0 -0.68 0.41 3.43 3.43 0 0 0 1.9 1.35Zm2.44 -1.73c0 0.71 -0.57 0.93 -1.23 0.94 0.11 -0.32 0.21 -0.64 0.32 -1 0.05 -0.13 0.09 -0.27 0.13 -0.4a2.74 2.74 0 0 1 0.55 0.16c0.1 0.1 0.23 0.16 0.23 0.3Zm-1.27 -3c-0.05 0.13 -0.12 0.24 -0.17 0.37s-0.16 0.44 -0.23 0.66a1.2 1.2 0 0 1 -0.27 -0.08C17.07 3.27 17 3.2 17 3c0 -0.48 0.39 -0.62 0.86 -0.62Z"
                ></path>
                <path d="M5.82 16.81c0.56 0 0.87 -0.16 0.86 -0.64 0 -0.25 0 -0.83 -0.87 -0.78s-0.73 1.4 0.01 1.42Z"
                ></path>
                <path
                    d="M7.82 15.35c-0.93 0.05 -0.73 1.4 0 1.42 0.56 0 0.86 -0.16 0.85 -0.64 0.01 -0.25 0.03 -0.83 -0.85 -0.78Z"
                ></path>
                <path
                    d="M8.65 20.91a8 8 0 0 1 -3.06 -0.64 6.12 6.12 0 0 1 -2.4 -1.83 5.35 5.35 0 0 1 -0.85 -4.14 6.14 6.14 0 0 1 1.92 -3.85 5.23 5.23 0 0 1 1.41 -0.86 10.18 10.18 0 0 1 1.66 -0.5c1.5 -0.32 2.21 -0.31 2.11 -0.8l0.05 -0.07c0.17 -0.2 0.36 -0.37 0.54 -0.55l0.64 -0.54 0.08 -0.07A10.59 10.59 0 0 0 11 8.24a1.92 1.92 0 0 0 0.34 0.65 0.39 0.39 0 1 0 0.66 -0.45 1.13 1.13 0 0 1 -0.13 -0.44l0 -1.34a2.63 2.63 0 0 0 -0.11 -0.66 1 1 0 0 0 -0.37 -0.5 0.67 0.67 0 0 0 -0.68 0 2.36 2.36 0 0 0 -0.26 0.17l-0.68 0.6L9.2 7c-0.12 0.21 -0.25 0.42 -0.35 0.64s-0.13 0.28 -0.19 0.43l-1.47 0.21A10.93 10.93 0 0 0 5 8.81l0 -1c0 -0.37 -0.06 -0.52 -0.09 -0.77l0.07 0 0.55 0.19 0.8 0.62a0.39 0.39 0 0 0 0.56 0 0.39 0.39 0 0 0 0 -0.55c-0.14 -0.2 -0.28 -0.39 -0.43 -0.57s-0.23 -0.25 -0.36 -0.38L5.43 6a4.56 4.56 0 0 0 -1.14 -0.39 0.87 0.87 0 0 0 -0.76 0.28 0.74 0.74 0 0 0 -0.14 0.69c0 0.18 0.26 0.63 0.25 0.65a6.15 6.15 0 0 1 0.38 1c0.1 0.31 0.18 0.62 0.25 0.94a6.8 6.8 0 0 0 -0.7 0.38A7 7 0 0 0 1 14.11a6.28 6.28 0 0 0 1.25 5.08 6.65 6.65 0 0 0 1.33 1.17 10 10 0 0 0 0 1.73 3 3 0 0 0 0.29 0.9 1.25 1.25 0 0 0 1 0.71 1.45 1.45 0 0 0 1.38 -0.62l0.42 -0.8a0.34 0.34 0 0 0 -0.07 -0.48 0.36 0.36 0 0 0 -0.49 0.07l-0.55 0.56a0.5 0.5 0 0 1 -0.34 0.07c-0.06 0 -0.11 -0.05 -0.14 -0.12a3.4 3.4 0 0 1 -0.38 -1l-0.12 -0.52a6.49 6.49 0 0 0 0.64 0.33 9 9 0 0 0 3.43 0.51 0.41 0.41 0 0 0 0.39 -0.4 0.38 0.38 0 0 0 -0.39 -0.39Z"
                ></path>
                <path
                    d="M4.33 18.19c0.18 0.12 0.37 0.23 0.55 0.33a5 5 0 0 0 0.6 0.24 5.07 5.07 0 0 0 2.33 0.24 3.81 3.81 0 0 0 2.14 -1 3 3 0 0 0 0.89 -2.05A1.93 1.93 0 0 0 9.56 14a0.39 0.39 0 0 0 -0.52 0.2 0.38 0.38 0 0 0 0.2 0.51 1.12 1.12 0 0 1 0.67 1.12 1.88 1.88 0 0 1 -0.71 1.29 2.68 2.68 0 0 1 -1.55 0.6 4.06 4.06 0 0 1 -1.74 -0.21l-0.84 -0.43a1.11 1.11 0 0 1 -0.62 -0.92 1.5 1.5 0 0 1 0.61 -1.1 4.2 4.2 0 0 1 1.65 -0.85 3.79 3.79 0 0 1 1.89 0 0.35 0.35 0 0 0 0.2 -0.67 4.75 4.75 0 0 0 -2.26 -0.22 5 5 0 0 0 -2.13 0.84 2.65 2.65 0 0 0 -1.23 1.92 2.31 2.31 0 0 0 1.15 2.11Z"
                ></path>
                <path
                    d="M4.31 12.73a9.54 9.54 0 0 1 2 -0.77 0.4 0.4 0 0 0 -0.17 -0.54 1.56 1.56 0 0 0 -1.26 -0.35 0.85 0.85 0 0 0 -0.32 0.09 1.33 1.33 0 0 0 -0.41 0.29 1.62 1.62 0 0 0 -0.43 1 0.35 0.35 0 0 0 0.17 0.46 0.34 0.34 0 0 0 0.42 -0.18Z"
                ></path>
                <path
                    d="M10.84 12.23a1.7 1.7 0 0 0 -0.84 -1.07 2.13 2.13 0 0 0 -0.33 -0.11 1.62 1.62 0 0 0 -0.49 0 2.66 2.66 0 0 0 -0.95 0.41 0.33 0.33 0 0 0 -0.26 0.41 0.34 0.34 0 0 0 0.42 0.25 9.65 9.65 0 0 1 1.75 0.51 0.39 0.39 0 0 0 0.69 -0.37Z"
                ></path>
                <path
                    d="M22.76 10.93a0.39 0.39 0 0 0 0.28 -0.48 0.41 0.41 0 0 0 -0.49 -0.28A3.07 3.07 0 0 0 21 11.23l-0.25 -0.05a5 5 0 0 0 -1.55 0A6.76 6.76 0 0 0 17 9.37a10.11 10.11 0 0 0 -3.88 -1 0.35 0.35 0 0 0 -0.4 0.31 0.38 0.38 0 0 0 0.32 0.38 9 9 0 0 1 3.48 1.18 5.45 5.45 0 0 1 2.29 2.58 4.91 4.91 0 0 1 0.3 3.39 5.8 5.8 0 0 1 -1 2.1 6.25 6.25 0 0 1 -4.76 2.37l0 -0.36a0.38 0.38 0 0 0 -0.35 -0.42 0.39 0.39 0 0 0 -0.45 0.33 7.86 7.86 0 0 1 -0.21 0.8 4.9 4.9 0 0 1 -0.26 0.67l-0.38 0.61a0.9 0.9 0 0 1 -0.31 0.27 1.36 1.36 0 0 1 -0.12 -0.12 2.68 2.68 0 0 1 -0.34 -0.44 9 9 0 0 1 -1 -2.16 0.33 0.33 0 0 0 -0.39 -0.28 0.34 0.34 0 0 0 -0.3 0.39 9 9 0 0 0 0.54 2.56 2.77 2.77 0 0 0 0.77 1.09c0.63 0.49 1.41 0.56 2.15 -0.56a2.44 2.44 0 0 0 0.25 -0.48c0.06 -0.17 0.12 -0.33 0.16 -0.49l0.12 -0.62a7.1 7.1 0 0 0 4.37 -1.2c-0.09 0.36 -0.19 0.75 -0.32 1.13a2.11 2.11 0 0 1 -0.47 0.81c-0.14 0.21 -0.28 0.28 -0.42 0.18a1.49 1.49 0 0 1 -0.53 -0.5 0.34 0.34 0 1 0 -0.65 0.22 1.94 1.94 0 0 0 1.15 1.47 1.42 1.42 0 0 0 1.47 -0.51 2.74 2.74 0 0 0 0.5 -1.13 7.2 7.2 0 0 0 0.05 -1.6A0.39 0.39 0 0 0 18 20a7.73 7.73 0 0 0 1 -0.9 6.56 6.56 0 0 0 1.38 -2.52A5.84 5.84 0 0 0 20.5 14a2.4 2.4 0 0 0 0.31 0.51 1.26 1.26 0 0 0 1.32 0.4 1.14 1.14 0 0 0 0.87 -1.13 3.23 3.23 0 0 0 -0.75 -1.93 3.4 3.4 0 0 0 -0.3 -0.23 2 2 0 0 1 0.81 -0.69ZM20 12.29c-0.06 -0.11 -0.15 -0.18 -0.21 -0.28 0.15 0 0.3 0 0.45 0.06a0.83 0.83 0 0 1 0.21 0.06 2.72 2.72 0 0 0 -0.19 0.86 6.61 6.61 0 0 0 -0.26 -0.7Zm0.91 0.63c0 -0.09 0 -0.55 0.28 -0.65a0.84 0.84 0 0 1 0.52 0 0.53 0.53 0 0 1 0.29 0.27c0.24 0.55 0.28 0.84 0.19 1a0.6 0.6 0 0 1 -0.7 0.24c-0.34 -0.14 -0.59 -0.72 -0.6 -0.86Z"
                ></path>
            </g>
        </svg>
    );
}