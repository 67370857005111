/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 17/04/24
 * Time: 11:18 PM
 * Project: kittydev_landing_site_react
 */
import React, {memo} from 'react';
import {Header} from "../../Components/Header";
import {CONTACT_EMAIL} from "../../LANDING_DATA";
import {Footer} from "../../Components/Footer";

export const RefundPolicy = memo(() => {

    return (
        <>
            <Header speed={2} limit={100}/>
            <div className={"listing-header"}>
                <h1 className="hero-heading-text text-shadow">
                    Refund Policy
                </h1>
            </div>
            <div className={"list-container"}>

                <div className={"list-holder"}>
                    <div>
                        <p className={"list-heading"}>
                            Custom-Built Software
                        </p>
                        <p className={"list-values"}>
                            If you have purchased custom-built software tailored specifically to your needs, or if you
                            have requested modifications to an existing software product, refunds will not be provided.
                            We invest significant time and resources in developing and customizing software to meet your
                            unique requirements, making it ineligible for refunds.
                        </p>
                    </div>

                    <div>
                        <p className={"list-heading"}>
                            Existing Software
                        </p>
                        <p className={"list-values"}>
                            For existing software products offered by Kittxdev, you may apply for a refund within 7
                            days from the date of delivery. After this period, refund requests will not be considered.
                            To be eligible for a refund, the software must not have been customized or
                            modified to your specifications.
                        </p>
                    </div>


                    <div>
                        <p className={"list-heading"}>Merchandise</p>
                        <p className={"list-values"}>
                            In the event that you receive damaged merchandise from Kittxdev, we offer replacement or
                            refund options. Please notify us immediately upon receiving the damaged item and return it
                            to us within a reasonable timeframe. Once we receive the returned item, we will initiate the
                            replacement process or issue a refund. Please note that courier charges for returning the
                            item are to be borne by you, and these charges are non-refundable.
                        </p>
                    </div>


                    <div>
                        <p className={"list-heading"}>
                            Non-Replacement Merchandise
                        </p>
                        <p className={"list-values"}>
                            If the damaged merchandise is non-replaceable due to stock availability or other reasons,
                            you will be eligible for a refund upon returning the item to us. Again, courier charges for
                            returning the item are your responsibility and are non-refundable.
                        </p>
                    </div>


                    <div>
                        <p className={"list-heading"}>
                            Refund Processing
                        </p>
                        <p className={"list-values"}>
                            Refunds will be processed within a reasonable timeframe after we receive the returned
                            item(s). The refund will be issued using the original payment method used for the purchase.
                        </p>
                    </div>


                    <div>
                        <p className={"list-heading"}>
                            Contact Us
                        </p>
                        <p className={"list-values"}>
                            If you have any questions or concerns regarding our refund policy, please contact us at
                            <a href={`mailto:${CONTACT_EMAIL}`}> contact@kittxdev.com.</a>.
                            We are here to assist you and ensure a positive experience with our
                            products and services.
                        </p>
                    </div>


                    <div>
                        <p className={"list-heading"}>
                            Changes to Refund Policy
                        </p>
                        <p className={"list-values"}>
                            Kittxdev reserves the right to amend or update this refund policy at any time without prior
                            notice. Any changes will be effective immediately upon posting on our website.
                        </p>
                    </div>
                    <p className={"list-values"}>
                        Thank you for choosing Kittxdev software. We appreciate your business and look forward to
                        serving you.
                    </p>
                </div>

            </div>
            <Footer/>
        </>
    );
})