import React, {memo, useEffect, useState} from 'react';
import AnimatedBG from "../../Components/3D/AnimatedBG";
import * as animationData_1 from "../../animation/animation_1.json";
import Lottie from "react-lottie";
import {
    CONTACT_BODY,
    CONTACT_EMAIL,
    CONTACT_SUBJECT,
    FAQ_QUESTIONS,
    LANDING_OUR_SERVICES_LIST,
    Software_Development_Process,
    TECH_STACK_IMAGE_SRC,
    WHY_COMPANY_WORK_WITH_US
} from "../../LANDING_DATA";
import {IconDisplayCard} from "../../Components/IconDisplayCard";
import AliceCarousel from "react-alice-carousel";
import {TechStackCard} from "../../Components/TechStackCard";
import {FaqCard} from "../../Components/FaqCard";
import {Header} from "../../Components/Header";
import {Footer} from "../../Components/Footer";

export const Home = memo(() => {


    const bgLoaded = () => {
        setBgImage(false)
    }
    const [bgImage, setBgImage] = useState(true)
    const errorCallback = () => {
        setBgImage(true)
    }

    useEffect(() => {
        const bg = new AnimatedBG()
        bg.init("background-holder", bgLoaded, errorCallback)
    }, []);


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData_1,
    };

    useEffect(() => {
        function updateFavicon() {
            const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
            const favicon = document.getElementById('favicon');
            if (darkMode) {
                favicon.href = 'https://ik.imagekit.io/kittydev/KittXDev/KittXdev_x_white_icon_uOCkfhTIz.svg';
            } else {
                favicon.href = 'https://ik.imagekit.io/kittydev/KittXDev/KittXdev_x_black_icon_dpEGlJui-.svg';
            }
        }

        updateFavicon();
        window.matchMedia('(prefers-color-scheme: dark)').addListener(updateFavicon);
    }, []);

    return (
        <>
            <div className="background-container">
                <div className="background-holder">
                    {/*<img*/}
                    {/*    alt={"Kittxdev-logo"}*/}
                    {/*    className={`background-item ${bgImage? "":"fadeBG"}`}*/}
                    {/*/>*/}
                </div>
            </div>
            <Header/>
            <div className="page-container">
                <main className="page-holder">
                    {/*    Hero section start    */}
                    <section className="hero-section-container">
                        <div className="particles-wrapper">
                            <div className="particles-absolute pa-5">
                                <Lottie options={defaultOptions}/>
                            </div>
                            <div
                                className="particles-absolute pa-4"
                            ><Lottie options={defaultOptions}/></div>
                            <div
                                className="particles-absolute pa-3"
                            ><Lottie options={defaultOptions}/></div>
                            <div
                                className="particles-absolute pa-6"
                            ><Lottie options={defaultOptions}/></div>
                            <div
                                className="particles-absolute pa-2"
                            ><Lottie options={defaultOptions}/></div>
                            <div
                                className="particles-absolute pa-1"><Lottie options={defaultOptions}/></div>
                        </div>
                        <h1 className="hero-heading-text text-shadow">
                            Crafting
                            <span className="gold-text-anim"> elite </span>
                            dreams into reality
                        </h1>
                        <p className="hero-sub-heading-text text-shadow secondary-Text">
                            Customized software development across various platforms including{" "}
                            <b>web</b>, <b>mobile</b>, and <b>IoT solutions</b>. Established
                            protocols for <b>seamless operations</b>. <b>Open collaboration</b>.{" "}
                            <b>Consistent project delivery</b>.
                        </p>
                    </section>
                    {/*        Hero section over   */}
                    {/*    Our services start    */}
                    <section className="our-service-container">
                        <div className="max-inner-width-handler">
                            <div className="child-center">
                                <h2 className="section-heading-text heading-center">
                                    <span>Our Services</span>
                                </h2>
                            </div>
                            <div className="our-service-slot-container pt-ul">
                                <div className="our-service-slot-holder">
                                    {LANDING_OUR_SERVICES_LIST.map(item => <IconDisplayCard data={item}
                                                                                            key={item.id}/>)}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*    Our services end    */}
                    {/*    techstack start    */}
                    <section className="tech-stack-container pt-ul">
                        <div className="child-center">
                            <h2 className="section-heading-text heading-center">
                                <span>Tech stack</span>
                            </h2>
                        </div>
                        <div className="tech-stack-container_l pt-">
                            <div className="tech-stack-holder">
                                <AliceCarousel autoPlay={true} infinite={true} disableDotsControls={true}
                                               disableButtonsControls={true} animationDuration={700} responsive={{
                                    0: {items: 2},
                                    568: {items: 4},
                                    1024: {items: 6},
                                }}>
                                    {TECH_STACK_IMAGE_SRC.map(item => <TechStackCard data={item} key={item.id}/>)}
                                </AliceCarousel>
                            </div>
                            <div className="tech-stack-holder-shader left-shader"/>
                            <div className="tech-stack-holder-shader right-shader"/>
                        </div>
                    </section>
                    {/*    techstack end    */}
                    {/*    Work with kittxdev start    */}
                    <section className="our-service-container pt-l">
                        <div className="max-inner-width-handler">
                            <div className="child-center">
                                <h2 className="section-heading-text heading-center">
                                    <span>Why Companies work with KittXDev</span>
                                </h2>
                            </div>
                            <div className="our-service-slot-container pt-ul">
                                <div className="our-service-slot-holder">
                                    {WHY_COMPANY_WORK_WITH_US.map(item => <IconDisplayCard data={item} key={item.id}/>)}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*    Work with kittxdev end    */}
                    {/*   Development Lyfecycle   Start  */}
                    <section className="development-cycle-container pt-ul">
                        <div className="max-inner-width-handler">
                            <div className="kdx-grid grid">
                                <div className="trusted-content development-cycle-header">
                                    <div className="content-sticky">
                                        <h2 className="section-heading-text">
                                            <span>Software Development Process</span>
                                        </h2>
                                        <div className="secondary-Text">
                                            At KittXDev, we offer comprehensive development services
                                            overseen by certified Project Managers. Each project commences
                                            with business analysis and concludes with a closure phase.
                                            Testing and quality assurance are conducted at every stage of
                                            the process.
                                        </div>
                                    </div>
                                </div>
                                <div className="trusted-content trusted-list development-cycle-list">
                                    <div className="trusted-overlay-container">
                                        <div className="trusted-white-overlay"/>
                                        <div className="trusted-white-overlay bottom"/>
                                    </div>
                                    {Software_Development_Process.map(item => {


                                            return <div className="trusted-item" key={item.id}>
                                                <IconDisplayCard data={item}/>
                                            </div>
                                        }
                                    )

                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*   Development Lyfecycle  End   */}
                    {/*    Faq start    */}
                    <section className="faq-container">
                        <div className="max-inner-width-handler">
                            <div className="child-center">
                                <h2 className="section-heading-text heading-center">
                                    <span>Frequently asked questions</span>
                                </h2>
                            </div>
                            <div className="faq-holder pt-ul">
                                {FAQ_QUESTIONS.map(item => <FaqCard data={item} key={item.id}/>)}
                            </div>
                        </div>
                    </section>
                    {/*    Faq end    */}
                    {/*   Contact start     */}
                    <section className="contact-outer-container pt-ul">
                        <div className="max-inner-width-handler contact-inner-container">
                            <h2 className="section-heading-text">
                                <span>Contact us</span>
                            </h2>
                            <p className="section-sub-heading-text secondary-Text heading-center pt-m">
            <span>
              Let's collaborate to craft a captivating and visually striking application that will propel your product to the forefront alongside industry giants.
            </span>
                            </p>
                            <div className={"pt-m contact-holder"}>
                                <div className={"contact-button-holder"}>
                                    <a className={"contact-button"}
                                       href={`mailto:${CONTACT_EMAIL}?subject=${CONTACT_SUBJECT}&body=${CONTACT_BODY}`}
                                    >Lets connect</a>
                                </div>
                                <div>
                                    <a className={"a-no-decoration"}
                                       href={`mailto:${CONTACT_EMAIL}?subject=${CONTACT_SUBJECT}&body=${CONTACT_BODY}`}
                                    >contact@kittxdev.com</a>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*<section style={{background: "black", paddingLeft: "30px", paddingTop: "10px"}}>*/}
                    {/*<div className={"contact-button-holder"} >*/}
                    {/*    <Link className={"contact-button"} style={{background:"rgba(255,255,255,0.53)"}}*/}
                    {/*          to={"/checkout"}*/}
                    {/*    >Checkout page</Link>*/}
                    {/*</div>*/}
                    {/*<div className="form-control checkbox-control">*/}
                    {/*    <label className="checkout-checkbox"><span style={{color: "red"}}>**&nbsp;</span>This page*/}
                    {/*        is only a sample checkout page hosted for*/}
                    {/*        payment gateway approval.<span style={{color: "red"}}>&nbsp;**</span></label>*/}
                    {/*</div>*/}
                    {/*</section>*/}
                    {/*   Contact end     */}
                    <Footer/>
                </main>
            </div>
        </>

    );
})