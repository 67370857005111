/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 30/01/24
 * Time: 10:26 pm
 * Project: landing_react
 */
import React, {memo, useState} from 'react';
import DownArrowIconRaw from "../Icon/DownArrowIconRaw";


export const FaqCard = memo(({data}) => {
    const {id, question, answer} = data
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div className="faq-item">
            <div className="faq-question" onClick={() => setIsOpen(old => !old)}>
                <div className="default-heading-text">{question}</div> <div className={`faq-arrow-container ${isOpen ? "open" : ""}`}><DownArrowIconRaw/></div>
            </div>
            <div className={`faq-answer ${isOpen ? "open" : ""}`}>
                <p className="kdx-sub-heading">
                    {answer}
                </p>
            </div>
        </div>
    );
})