/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 17/04/24
 * Time: 10:00 PM
 * Project: kittydev_landing_site_react
 */
import React, {memo} from 'react';
import {Header} from "../../Components/Header";
import {CONTACT_EMAIL} from "../../LANDING_DATA";
import {Footer} from "../../Components/Footer";

export const TermsAndConditions = memo(() => {
    return (
        <>
            <Header speed={2} limit={100}/>
            <div className={"listing-header"}>
                <h1 className="hero-heading-text text-shadow">
                    Terms and Conditions
                </h1>
            </div>
            <div className={"list-container"}>

                <div className={"list-holder"}>
                    <div>
                        <p className={"list-heading"}>
                            Cookies
                        </p>
                        <p className={"list-values"}>
                            We utilize cookies on the Kittxdev website. By accessing and using Kittxdev's website, you
                            consent to the use of cookies in accordance with Kittxdev's privacy policy. Cookies are
                            utilized in specific areas of our site to facilitate
                            functionality and ease of use for visitors.
                        </p>
                    </div>

                    <div>
                        <p className={"list-heading"}>
                            License
                        </p>
                        <p className={"list-values"}>
                            All intellectual property rights are reserved. You may view and/or print pages from
                            kittxdev.com for personal use, subject to
                            the restrictions outlined in these terms of service.
                        </p>
                        <p className={"list-values"}>
                            You are not permitted to:
                        </p>
                        <ul style={{paddingLeft: "30px"}}>
                            <li className={"list-values"}>
                                Republish material from kittxdev.com.
                            </li>
                            <li className={"list-values"}>
                                Sell, rent, or sublicense material from kittxdev.com.
                            </li>
                            <li className={"list-values"}>
                                Reproduce, duplicate, or copy material from kittxdev.com.
                            </li>
                            <li className={"list-values"}>
                                Redistribute content from Kittxdev, unless the content is specifically intended for
                                redistribution.
                            </li>
                        </ul>
                    </div>


                    <div>
                        <p className={"list-heading"}>User Comments</p>
                        <ul style={{paddingLeft: "30px"}}>
                            <li className={"list-values"}>
                                This Agreement takes effect from the date of inception.
                            </li>
                            <li className={"list-values"}>
                                Use of Kittxdev's logo or artwork for linking purposes is prohibited without a trademark
                                license agreement.
                            </li>
                            <li className={"list-values"}>
                                Certain sections of this website offer users the opportunity to post and exchange
                                opinions, information, material, and data ("Comments"). Kittxdev does not prescreen,
                                edit, publish, or review Comments prior to their appearance on the website. Comments do
                                not necessarily reflect the views or opinions of Kittxdev, its agents, or affiliates.
                                The responsibility for Comments lies with the individuals who post them. To the extent
                                permitted by applicable laws, Kittxdev shall not be liable for Comments or for any
                                losses, costs, liabilities, damages, or expenses arising from the use of, posting of, or
                                appearance of Comments on this website.
                            </li>
                            <li className={"list-values"}>
                                Kittxdev reserves the right to monitor all Comments and to remove any Comments deemed
                                inappropriate, offensive, or in breach of these Terms of Service, at its absolute
                                discretion.
                            </li>
                            <li className={"list-values"}>
                                By posting Comments, you grant Kittxdev a non-exclusive, royalty-free license to use,
                                reproduce, and edit your Comments in any form, format, or media, and authorize others to
                                do the same.
                            </li>
                        </ul>
                    </div>


                    <div>
                        <p className={"list-heading"}>
                            Hyperlinking to Our Content
                        </p>
                        <p className={"list-values"}>
                            The following entities may link to our website without prior written approval:
                        </p>
                        <ul style={{paddingLeft: "30px"}}>
                            <li className={"list-values"}>
                                Government agencies.
                            </li>
                            <li className={"list-values"}>
                                Search engines.
                            </li>
                            <li className={"list-values"}>
                                News organizations.
                            </li>
                        </ul>
                    </div>


                    <div>
                        <p className={"list-heading"}>
                            Contact Information
                        </p>
                        <p className={"list-values"}>
                            This Terms of Service page was adapted from the terms & conditions generator provided by
                            Kittxdev. If you have any inquiries regarding our terms, please contact us at
                            <a href={`mailto:${CONTACT_EMAIL}`}> contact@kittxdev.com.</a>
                        </p>
                    </div>

                </div>

            </div>
            <Footer/>
        </>
    );
})