import MobileDevIconRaw from "./Icon/MobileDevIconRaw";
import WebDevIconRaw from "./Icon/WebDevIconRaw";
import CustomSoftIconRaw from "./Icon/CustomSoftIconRaw";
import EnterpriseSoftIconRaw from "./Icon/EnterpriseSoftIconRaw";
import EnterpriseAppIconRaw from "./Icon/EnterpriseAppIconRaw";
import CrmIconRaw from "./Icon/CrmIconRaw";
import MoneySaving from "./Icon/MoneySaving";
import SupportIconRaw from "./Icon/SupportIconRaw";
import AdvancedExpertiseIconRaw from "./Icon/AdvancedExpertiseIconRaw";
import NotesIconRaw from "./Icon/NotesIconRaw";
import WireframeIconRaw from "./Icon/WireframeIconRaw";
import DevelopmentIconRaw from "./Icon/DevelopmentIconRaw";
import RatingIconRaw from "./Icon/RatingIconRaw";
import LaunchIconRaw from "./Icon/LaunchIconRaw";
import Support2IconRaw from "./Icon/Support2IconRaw";

/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 30/01/24
 * Time: 1:21 am
 * Project: landing_react
 */
export const LANDING_OUR_SERVICES_LIST = [
    {
        id: "LANDING_OUR_SERVICES_LIST_1",
        title: "Mobile app development services",
        // price: "Rs 15,000",
        description: "We design and develop premium mobile apps, focusing on user experience and leveraging the latest technology to achieve our client's business goals.",
        icon: MobileDevIconRaw
    },
    {
        id: "LANDING_OUR_SERVICES_LIST_2",
        title: "Web development services",
        // price: "Rs 5,000",
        description: "We excel in crafting top-notch websites that prioritize user experience and leverage cutting-edge technology to align with our clients' aspirations.",
        icon: WebDevIconRaw
    }, {
        id: "LANDING_OUR_SERVICES_LIST_3",
        title: "Custom software development services",
        // price: "Rs 20,000",
        description: "We're skilled at developing flexible software solutions for businesses of any size, ensuring their specific needs are met to drive growth and inspire innovation.",
        icon: CustomSoftIconRaw
    },
    {
        id: "LANDING_OUR_SERVICES_LIST_4",
        title: "Enterprise Software Development",
        // price: "Rs 40,000",
        description: "Our enterprise software solutions are shaped by profound industry insight and the forefront of advanced technological innovation.",
        icon: EnterpriseSoftIconRaw
    },
    {
        id: "LANDING_OUR_SERVICES_LIST_5",
        title: "Enterprise app integration",
        // price: "Rs 30,000",
        description: "We integrate various enterprise systems to establish interconnected ecosystems, streamlining seamless enterprise management.",
        icon: EnterpriseAppIconRaw
    },
    {
        id: "LANDING_OUR_SERVICES_LIST_6",
        title: "Customer Relationship Management (CRM)",
        // price: "Rs 25,000",
        description: "We design versatile CRM solutions tailored for marketing, sales, and service teams, serving as essential tools for streamlined customer management.",
        icon: CrmIconRaw
    }
]


export const TECH_STACK_IMAGE_SRC = [
    {
        id: "TECH_STACK_IMAGE_SRC_1",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/ico_php_gG-QU_4Ng.svg",
        name: "Php"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_ico_kotlin_g0izXBUIm",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/ico_kotlin_g0izXBUIm.svg",
        name: "Kotlin"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_ico_java_Ct8uhdxEXy",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/ico_java_Ct8uhdxEXy.svg",
        name: "Java"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_ico_python_jhFpUfUbQs",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/ico_python_jhFpUfUbQs.svg",
        name: "Python",
    },
    {
        id: "TECH_STACK_IMAGE_SRC_ico_sql_9KKPT0fZa4",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/ico_sql_9KKPT0fZa4.svg",
        name: "ico_sql_9KKPT0fZa4",
    },
    {
        id: "TECH_STACK_IMAGE_SRC_javascript_LO22oGmQ6",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/ts-javascript_LO22oGmQ6.svg",
        name: "JS",
    },
    {
        id: "TECH_STACK_IMAGE_SRC_ico_typescript_pFohJ0MIL",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/ico_typescript_pFohJ0MIL.svg",
        name: "TS",
    },
    {
        id: "TECH_STACK_IMAGE_SRC_ico_swift_cmbhVpkCBn",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/ico_swift_cmbhVpkCBn.svg",
        name: "Swift",
    },
    {
        id: "TECH_STACK_IMAGE_SRC_reactjs",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/react.js_HSw28VO_Mj.svg",
        name: "ReactJS",
    },
    {
        id: "TECH_STACK_IMAGE_SRC_node_pjELaLusRX",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/node_pjELaLusRX.svg",
        name: "Node",
    },
    {
        id: "TECH_STACK_IMAGE_SRC_mongodb_K8uEgGTYk0",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/mongodb_K8uEgGTYk0.svg",
        name: "MongoDB"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_sqlite_2T1xUtByZ-",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/sqlite_2T1xUtByZ-.svg",
        name: "SqlLite"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_elasticsearch_FFO6w_R6_k",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/elasticsearch_FFO6w_R6_k.svg",
        name: "ElasticSearch"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_django_8bM9b66sO9",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/django_8bM9b66sO9.svg",
        name: "Django"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_react_native.js_C0LguLFT9",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/react_native.js_C0LguLFT9.svg",
        name: "ReactNative"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_mysql",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/my_sql_ITTJIWZRW.svg",
        name: "MySql"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_p_sql_m3TplVWbG",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/p_sql_m3TplVWbG.svg",
        name: "Postgresql"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_redis_GVsJHQbTZr",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/redis_GVsJHQbTZr.svg",
        name: "Redis"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_windows_TFFGoa9z3X",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/windows_TFFGoa9z3X.svg",
        name: "Windows"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_android_3UwTBLX_7X",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/android_3UwTBLX_7X.svg",
        name: "Android"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_google_cloud_XfLf-qXHX",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/google_cloud_XfLf-qXHX.svg",
        name: "Google Cloud"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_macos_8K7NT7OhXz",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/macos_8K7NT7OhXz.svg",
        name: "MaxOs"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_docker_ysoxGXQgQ",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/docker_ysoxGXQgQ.svg",
        name: "Docker"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_linux_jpwMsuN5pI",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/linux_jpwMsuN5pI.svg",
        name: "Linux"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_ms_azure_bb3tmP-SB8",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/ms_azure_bb3tmP-SB8.svg",
        name: "Azure"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_aws_FOJ3WJitA2",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/aws_FOJ3WJitA2.svg",
        name: "Aws"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_ios_W_2TFJA6B0",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/ios_W_2TFJA6B0.svg",
        name: "Ios"
    },
    {
        id: "TECH_STACK_IMAGE_SRC_kubernetes_GxnfKGB_pm",
        src: "https://ik.imagekit.io/kittydev/Temp%20Testing/kubernetes_GxnfKGB_pm.svg",
        name: "Kubernetes"
    }
]


export const WHY_COMPANY_WORK_WITH_US = [
    {
        id: "WHY_COMPANY_WORK_WITH_US_1",
        title: "Enhancement of Cost Efficiency",
        description: "Our competitive pricing ensures quality and savings. We offer transparent pricing and flexible engagement models tailored to your needs.",
        icon: MoneySaving
    },
    {
        id: "WHY_COMPANY_WORK_WITH_US_2",
        title: "Reliable Support & Assistance.",
        description: "At KittXDev Technology, clear communication is vital for project success. We focus on transparency and prompt client support.",
        icon: SupportIconRaw
    },
    {
        id: "WHY_COMPANY_WORK_WITH_US_3",
        title: "Advanced Expertise in Technology",
        description: "We're a leading software engineering firm with broad expertise. Choose us for outsourcing, where we excel in cutting-edge solutions.",
        icon: AdvancedExpertiseIconRaw
    }
]

export const Software_Development_Process = [
    {
        id: "Software_Development_Process_1",
        title: "Requirements Gathering",
        description: "The discovery phase is a critical way to identify key players, define the project goal, orchestrate technical aspects, formalize scope and requirements, define a tech stack, and shape team composition.",
        icon: NotesIconRaw
    },
    {
        id: "Software_Development_Process_2",
        title: "Design and Prototyping",
        description: "Our designers will create prototypes reflecting user experience goals, considering functional and non-functional requirements, tech stack, data sources, and integration needs.",
        icon: WireframeIconRaw
    },
    {
        id: "Software_Development_Process_3",
        title: "Development",
        description: "In our development process, we employ a Work Breakdown Structure, systematically dividing the project scope into manageable segments. This approach streamlines software delivery by enabling precise tracking of completed tasks and upcoming development goals.",
        icon: DevelopmentIconRaw
    },
    {
        id: "Software_Development_Process_4",
        title: "Quality Assurance and Testing",
        description: "The code is scrutinized through rigorous reviews and evaluations, including peer assessments and cross-checks. We utilize various predefined types of manual and automated testing to ensure the highest quality. Additionally, continuous assessments are conducted to gauge the alignment of business requirements with the system's functionality.",
        icon: RatingIconRaw
    },
    {
        id: "Software_Development_Process_5",
        title: "Launch",
        description: "Your tech platform undergoes thorough inspection for seamless deployment, whether on the cloud or on-premises. User acceptance testing ensures all requirements are met. DevOps manage security, disaster recovery, and backup for a smooth rollout.",
        icon: LaunchIconRaw
    },
    {
        id: "Software_Development_Process_6",
        title: "Maintenance & Support",
        description: "Upon request, ongoing maintenance is provided, drawing upon our project knowledge base to promptly resume custom software development as required. We ensure seamless adjustments to the process as needed.",
        icon: Support2IconRaw
    }
]

export const FAQ_QUESTIONS = [
    {
        id: "FAQ_QUESTIONS_1",
        question: "What is the typical development process for mobile app projects?",
        answer: "Our mobile app development process typically involves initial consultation, UI/UX design, development, testing, and deployment. We follow an iterative approach to ensure client feedback is incorporated at every stage for optimal results."
    },
    {
        id: "FAQ_QUESTIONS_2",
        question: "How do you ensure data security in custom server development?",
        answer: "Data security is paramount in custom server development. We employ industry-standard encryption techniques, access controls, and regular security audits to safeguard sensitive information and ensure compliance with relevant regulations."
    },
    {
        id: "FAQ_QUESTIONS_3",
        question: "Can you integrate the CRM solution with existing systems?",
        answer: "Yes, our CRM solutions are designed to seamlessly integrate with your existing systems such as ERP software, email platforms, or other third-party applications. We prioritize interoperability to enhance workflow efficiency."
    },
    {
        id: "FAQ_QUESTIONS_4",
        question: "What support and maintenance services do you offer post-launch?",
        answer: "We provide comprehensive support and maintenance services post-launch, including bug fixes, performance monitoring, feature enhancements, and regular updates to keep your application secure and up-to-date with evolving technologies."
    },
    {
        id: "FAQ_QUESTIONS_5",
        question: "How do you approach scalability for enterprise-level projects?",
        answer: "Scalability is a core consideration for enterprise projects. We design our solutions with scalability in mind, utilizing cloud infrastructure, microservices architecture, and load balancing techniques to ensure seamless performance even under high user loads."
    },
]

export const CONTACT_EMAIL = "contact@kittxdev.com"
export const CONTACT_SUBJECT = "Request%20for%20Software%20Development%20Services"
export const CONTACT_BODY = "Hello,%0D%0A%0D%0AI%20am%20interested%20in%20your%20software%20development%20services%20and%20would%20like%20to%20discuss%20a%20potential%20project.%0D%0A%0D%0AHere%20are%20some%20details%20about%20the%20project%20I%20have%20in%20mind:%0D%0A-%20[Describe%20your%20project%20here]%0D%0A%0D%0APlease%20let%20me%20know%20if%20we%20can%20arrange%20a%20meeting%20to%20discuss%20further.%0D%0A%0D%0AThank%20you.%0D%0A"