/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 30/01/24
 * Time: 1:23 am
 * Project: landing_react
 */
import React from 'react';

export default function MobileDevIconRaw() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3.5 30 30" className="kdx-detail-slot-icon">
            <g>
                <path
                    d="M13.21 22.09a11.7 11.7 0 0 1 -0.81 -0.87 5.9 5.9 0 0 1 -0.67 -1 1.06 1.06 0 0 1 -0.16 -0.56 1 1 0 0 1 0.24 -0.48 4.08 4.08 0 0 1 0.48 -0.62c0.51 -0.58 1 -1.25 1.45 -1.82a5.38 5.38 0 0 1 0.64 -0.69 0.3 0.3 0 0 0 0 -0.43 0.31 0.31 0 0 0 -0.43 0c-0.26 0.19 -0.52 0.42 -0.79 0.66 -0.54 0.5 -1.08 1.08 -1.66 1.57a5.12 5.12 0 0 0 -0.79 0.88 1.83 1.83 0 0 0 -0.35 0.95 2.23 2.23 0 0 0 0.3 1.13 6.2 6.2 0 0 0 0.9 1.14c0.17 0.17 0.56 0.51 1 0.86 0.59 0.48 1.25 1 1.5 1.17a0.35 0.35 0 0 0 0.49 -0.05 0.34 0.34 0 0 0 -0.06 -0.48c-0.18 -0.26 -0.77 -0.83 -1.28 -1.36Z"/>
                <path
                    d="M21.94 19.1a6.18 6.18 0 0 0 -0.87 -1.26l-2.58 -2.32a0.3 0.3 0 0 0 -0.43 0 0.31 0.31 0 0 0 0 0.43l2.2 2.63c0.16 0.18 1.11 1.18 0.72 1.27v-0.06l0 0.06a2.7 2.7 0 0 1 -0.43 0.91 7.87 7.87 0 0 1 -0.77 1l-1.52 1.67a0.34 0.34 0 1 0 0.46 0.51l1.7 -1.5a6.6 6.6 0 0 0 1.18 -1.23 3.15 3.15 0 0 0 0.53 -1.21 1.57 1.57 0 0 0 -0.19 -0.9Z"/>
                <path
                    d="M16.48 2.25a4.77 4.77 0 0 0 -0.37 -0.83 2.41 2.41 0 0 0 -1 -1 4.25 4.25 0 0 0 -1.97 -0.36L7.1 0a11.22 11.22 0 0 0 -2.34 0.17 3.66 3.66 0 0 0 -1.61 0.7 2.09 2.09 0 0 0 -0.64 1 5.93 5.93 0 0 0 -0.17 1.47L1.86 16a13.93 13.93 0 0 0 0.22 3.17 2.73 2.73 0 0 0 0.69 1.37 2.32 2.32 0 0 0 1.23 0.4 22.77 22.77 0 0 0 2.68 0L9 21a0.3 0.3 0 0 0 0.3 -0.31 0.3 0.3 0 0 0 -0.31 -0.3l-2.23 -0.09c-0.52 0 -1.44 0 -2.25 -0.08a2.22 2.22 0 0 1 -1.19 -0.31 2.46 2.46 0 0 1 -0.49 -1.39 14.92 14.92 0 0 1 0 -2.56c0.08 -1.48 0.31 -4.7 0.48 -7.55 0.13 -2.31 0.23 -4.36 0.25 -5.06a5.74 5.74 0 0 1 0.08 -1 1.09 1.09 0 0 1 0.32 -0.64 2.45 2.45 0 0 1 1 -0.41c0.07 0.47 0.26 1.27 0.43 1.9a3.88 3.88 0 0 0 0.43 1.08 3.53 3.53 0 0 0 1.2 0.41c0.81 0.17 1.89 0.32 2.42 0.39a11.86 11.86 0 0 0 2 0 5 5 0 0 0 1.41 -0.28 1.77 1.77 0 0 0 0.67 -0.8 21 21 0 0 0 1 -2.78 1.46 1.46 0 0 1 0.75 0.66 3.66 3.66 0 0 1 0.32 0.66 3.83 3.83 0 0 1 0.19 0.72A31.54 31.54 0 0 1 16 7.08c0 2.22 -0.06 4.64 -0.14 5.8a0.34 0.34 0 0 0 0.32 0.37 0.35 0.35 0 0 0 0.37 -0.31c0.1 -1.17 0.23 -3.62 0.26 -5.85a29.5 29.5 0 0 0 -0.12 -3.95 5 5 0 0 0 -0.21 -0.89Zm-3.43 0.64c-0.49 1 -0.2 1.11 -1.74 1.15A14.57 14.57 0 0 1 9.47 4l-3.12 -0.39c-0.14 -0.28 -0.38 -0.9 -0.58 -1.47a9.46 9.46 0 0 1 -0.28 -0.91 11.23 11.23 0 0 1 1.59 -0.09l6.06 0a5.09 5.09 0 0 1 0.68 0c-0.17 0.39 -0.46 1.11 -0.77 1.75Z"/>
            </g>
        </svg>
    );
}