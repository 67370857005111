/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 30/01/24
 * Time: 1:35 am
 * Project: landing_react
 */
import React from 'react';

export default function CrmIconRaw() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3.5 30 30" className="kdx-detail-slot-icon">
            <g>
                <path d="m12.66 12.69 -0.15 -0.35c-1 -0.45 -1.43 0.57 -0.87 0.95 0.36 0.25 1.24 -0.07 1.02 -0.6Z"/>
                <path
                    d="M8.61 14.92c0.07 0.12 0.41 0.65 0.48 0.72a0.79 0.79 0 0 0 0.77 0.16c0.13 0 0.16 0 0.69 -0.35l0.14 0.07c0 1 0 1.53 1.44 1.48 0.15 0 0.78 -0.07 0.88 -0.1 0.56 -0.17 0.63 -0.79 0.56 -1.38a1.53 1.53 0 0 0 0.89 0.27c0.79 -0.08 1 -0.92 1.3 -1.37a0.57 0.57 0 0 0 -0.08 -0.67 5.71 5.71 0 0 0 -0.49 -0.5c-0.4 -0.37 -0.35 -0.22 -0.35 -0.36 0.28 -0.19 0.66 -0.41 0.78 -0.73 0.28 -0.71 -0.32 -1.25 -0.6 -1.71a0.47 0.47 0 0 0 -0.5 -0.21c-1.3 0.32 -0.91 0.49 -1.31 0.3a2.14 2.14 0 0 0 -0.24 -1c-0.43 -0.52 -1.19 -0.27 -1.69 -0.25a0.33 0.33 0 0 0 -0.3 0.25c-0.29 1.07 0 0.89 -0.36 1 -0.14 0.07 -0.09 0.08 -0.2 0a1.84 1.84 0 0 0 -0.7 -0.38c-0.53 -0.1 -1 0.74 -1.22 1.23a0.74 0.74 0 0 0 -0.16 0.56 1.28 1.28 0 0 0 0.78 0.78c0.12 0.06 0.09 0 0.08 0.21s0 0.12 -0.11 0.19a1.83 1.83 0 0 0 -0.7 0.45c-0.28 0.36 -0.03 0.92 0.22 1.34Zm0.6 -3.14c0 -0.08 0.41 -0.77 0.48 -0.85 0.44 0.25 0.29 0.16 0.64 0.38a0.35 0.35 0 0 0 0.4 0c0.49 -0.21 0.56 -0.13 0.85 -1.4 0.18 0 0.73 -0.08 0.84 0.07 0.09 0.62 -0.07 1.08 0.32 1.22s0.65 0.39 0.89 0.28a2.91 2.91 0 0 1 0.82 -0.26 2.11 2.11 0 0 1 0.3 0.55c0.26 0.22 -1 0.24 -0.94 0.95 0 1.08 -0.08 0.66 0.8 1.54 0 0.08 -0.15 0.29 -0.2 0.37 -0.06 -0.15 -0.48 -0.2 -0.6 -0.26 -0.32 -0.15 -0.5 0 -1 0.27a0.57 0.57 0 0 0 -0.4 0.59l0 0.62 -0.61 0c0 -1.13 0.11 -1 -1 -1.46a0.53 0.53 0 0 0 -0.59 0l-0.36 0.24a1.23 1.23 0 0 1 -0.23 0.09l-0.39 -0.62c1 -0.59 0.87 -0.31 0.84 -1.5a0.46 0.46 0 0 0 -0.22 -0.44c-0.41 -0.25 -0.26 -0.16 -0.64 -0.38Z"/>
                <path
                    d="M9 8.33a3.36 3.36 0 0 1 1 -1.49 4.64 4.64 0 0 1 1.16 -1 3.52 3.52 0 0 0 1.77 -0.06 4.25 4.25 0 0 1 1 0.74 6.89 6.89 0 0 1 1.24 1.86 0.34 0.34 0 1 0 0.66 -0.14 2.9 2.9 0 0 0 -1.69 -2.78l-0.34 -0.1a2.81 2.81 0 0 0 0.58 -3.65C14.05 1.23 13.24 0.57 13 1.1a0.34 0.34 0 0 0 0.17 0.45c1.43 0.69 0.91 3.78 -1.83 3.11 -1.78 -0.37 -1.61 -3.68 1 -3.5a0.3 0.3 0 1 0 0.06 -0.6 2.7 2.7 0 0 0 -2.14 4.82 4.29 4.29 0 0 0 -0.9 0.52 2.57 2.57 0 0 0 -1 2.45 0.3 0.3 0 1 0 0.64 -0.02Z"/>
                <path
                    d="M7.07 21.28A2.24 2.24 0 0 0 5.47 20a2.83 2.83 0 0 0 0.58 -3.65c-0.32 -0.43 -1.12 -1.08 -1.36 -0.56a0.34 0.34 0 0 0 0.16 0.45c1.09 0.52 1 2.26 0 2.92a2.21 2.21 0 0 1 -1.85 0.23c-1.79 -0.37 -1.61 -3.68 1 -3.5a0.3 0.3 0 0 0 0.06 -0.6 2.7 2.7 0 0 0 -2.14 4.82 4.29 4.29 0 0 0 -0.9 0.52 2.55 2.55 0 0 0 -1 2.45 0.3 0.3 0 0 0 0.6 0 2.23 2.23 0 0 1 0.62 -1 6.59 6.59 0 0 1 1.56 -1.49 3.52 3.52 0 0 0 1.78 -0.06A3.74 3.74 0 0 1 6 21.67a5.71 5.71 0 0 1 0.84 1.39 0.34 0.34 0 0 0 0.67 -0.14 4.34 4.34 0 0 0 -0.44 -1.64Z"/>
                <path
                    d="M24 23a3.24 3.24 0 0 0 -1.21 -2.56 2.6 2.6 0 0 0 -0.82 -0.32c1.21 -0.73 1.6 -3.54 -0.33 -4.38a0.34 0.34 0 0 0 -0.28 0.62c1.37 0.67 1 3.79 -1.83 3.11 -1.77 -0.37 -1.65 -3.61 1 -3.5a0.3 0.3 0 1 0 0.06 -0.6 2.7 2.7 0 0 0 -2.14 4.82A3.32 3.32 0 0 0 17 21.28a2.56 2.56 0 0 0 -0.44 1.9 0.3 0.3 0 1 0 0.6 0 3.39 3.39 0 0 1 1 -1.49 4.55 4.55 0 0 1 1.17 -1.05 3.54 3.54 0 0 0 1.77 -0.06 4.58 4.58 0 0 1 1.79 1.76 5.51 5.51 0 0 1 0.45 0.84A0.34 0.34 0 0 0 24 23Z"/>
                <path
                    d="M3.07 12.34a2.21 2.21 0 0 0 0.44 0.64c0.25 0.23 0.74 0.19 0.71 -0.18a0.3 0.3 0 0 0 -0.28 -0.3 3.93 3.93 0 0 1 -0.39 -2A7.57 7.57 0 0 1 4.67 7a2.38 2.38 0 0 1 1.52 -1.09s-0.36 0.59 -0.69 1.17c0 0.08 -0.26 0.33 -0.24 0.52a0.34 0.34 0 0 0 0.66 0.11c0.07 0 0.17 -0.15 0.21 -0.19 0.36 -0.4 1.47 -0.91 1.26 -1.88 -0.08 -0.37 -0.46 -0.55 -0.63 -0.68a6.7 6.7 0 0 0 -1.12 -0.7A6.27 6.27 0 0 0 4.8 4c-0.07 0 -0.39 0.37 0 0.61 0.89 0.61 0.51 0.43 0.86 0.75 -1.97 0.36 -4.17 3.84 -2.59 6.98Z"/>
                <path
                    d="M15.63 19.07c-0.77 0.73 -3.07 0.72 -4 0.65a10.75 10.75 0 0 1 -2.46 -0.4l1.14 -0.62a0.56 0.56 0 0 0 0.17 -0.07c0.35 0.13 0.64 -0.44 0.12 -0.64a1.32 1.32 0 0 0 -0.6 0.01c-0.71 0.15 -1.75 0.27 -2.21 0.85a0.58 0.58 0 0 0 -0.08 0.63c1.94 3.71 2.95 1.95 2.25 1.68a2.4 2.4 0 0 1 -0.75 -1.08 7.63 7.63 0 0 0 4.05 0.74 4.1 4.1 0 0 0 2.78 -1.38 0.3 0.3 0 0 0 -0.41 -0.37Z"/>
                <path
                    d="M19.32 8.52a7.47 7.47 0 0 1 0.55 2.77 10.77 10.77 0 0 0 -1.16 -1c-0.16 -0.12 -0.53 -0.5 -0.87 -0.46a0.34 0.34 0 0 0 -0.24 0.09c-0.07 0.08 -0.25 0.43 0.11 0.53l0.13 0a15.87 15.87 0 0 0 1.85 2.26 0.94 0.94 0 0 0 1 0.07A5 5 0 0 0 22 11.17c0.71 -1.58 -0.47 -1.51 -0.47 -1s-0.51 0.9 -0.82 1.19l0.06 -0.88a5.43 5.43 0 0 0 -2.42 -5c-0.18 -0.1 -1.66 -0.86 -1.91 -0.73s-0.27 0.28 0 0.52c0.39 0.48 1.85 0.56 2.88 3.25Z"/>
            </g>
        </svg>
    );
}