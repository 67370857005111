/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 01/02/24
 * Time: 8:52 pm
 * Project: landing_react
 */
import React from 'react';

export default function NotesIconRaw(
    {
        strokeWidth = 3,
        className = "w-1",
        stroke = 'currentColor',
        fill = "none"
    }
) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3.5 30 30" className="kdx-detail-slot-icon">
            <g>
                <path
                    d="M20.52 13.52a0.52 0.52 0 0 0 -0.42 -0.52c0.63 0.12 -4.18 -0.72 -4.43 0 -0.09 0.15 0 0.33 0.27 0.42a18.81 18.81 0 0 1 3.57 0.49l0 2.92a19.85 19.85 0 0 1 -3.29 0.05 10.37 10.37 0 0 1 -0.11 -2.65 0.33 0.33 0 0 0 -0.64 -0.16 3 3 0 0 0 -0.07 0.79c-0.05 0.93 -0.42 2.22 0.28 2.77s3.78 0.44 4.44 0.22 0.69 -0.26 0.4 -4.33Z"/>
                <path
                    d="M17 18.78c0.62 0.12 -4.19 -0.72 -4.44 0 -0.09 0.15 0 0.33 0.27 0.43a18 18 0 0 1 3.57 0.48l0 2.92a19.93 19.93 0 0 1 -3.3 0.06A9.88 9.88 0 0 1 13 20a0.34 0.34 0 0 0 -0.65 -0.17 3 3 0 0 0 -0.07 0.79c-0.05 0.93 -0.42 2.23 0.28 2.78s3.78 0.44 4.44 0.21 0.69 -0.63 0.42 -4.37a0.5 0.5 0 0 0 -0.42 -0.46Z"/>
                <path
                    d="M23.83 19.25a0.5 0.5 0 0 0 -0.41 -0.47 17.26 17.26 0 0 0 -4.06 -0.23c-0.45 0.07 -0.61 0.49 -0.11 0.65a17.89 17.89 0 0 1 3.57 0.48l0 2.92a19.91 19.91 0 0 1 -3.3 0.06 7.39 7.39 0 0 1 -0.13 -2c0 -0.47 0.18 -0.9 -0.23 -1s-0.44 0.58 -0.46 1a6.51 6.51 0 0 0 0 2.41 1.25 1.25 0 0 0 0.94 0.64c0.68 0.19 3.67 0.29 4.19 -0.31a1.65 1.65 0 0 0 0.17 -1.09c0 -1 -0.17 -3.06 -0.17 -3.06Z"/>
                <path
                    d="M10.15 20.09c-7.82 0.12 -4.49 0.13 -7.9 -0.27 -0.51 -0.06 -0.75 -0.44 -0.89 -0.9 -0.27 -0.89 -0.2 -1.09 -0.08 -9.76A21.12 21.12 0 0 1 1.5 4c0.27 -1.14 -0.03 -1.5 1.5 -1.48 0 0.36 -0.14 1.71 -0.11 1.95 0.1 0.79 0.88 0.67 1.19 0.75 1.59 0.39 2.18 -0.81 2.06 -2.48a2.69 2.69 0 0 0 1.53 0c0 0.11 -0.1 1.45 -0.08 1.63 0.1 0.79 0.88 0.67 1.19 0.76 1.56 0.37 2.17 -0.78 2.06 -2.42 0.71 0.22 0.76 0.18 1.82 -0.06 0 0.11 -0.09 1.45 -0.07 1.58a0.73 0.73 0 0 0 0.55 0.66c1.67 0.44 2.65 0 2.71 -1.82a8.69 8.69 0 0 1 1 0.1c0.26 0 0.47 0.08 0.49 0.29l0.38 7.26a0.31 0.31 0 0 0 0.62 0 20.07 20.07 0 0 0 0 -7.45c-0.25 -1.08 -1.38 -1 -2.49 -1a5.5 5.5 0 0 0 -0.47 -1.7 1.51 1.51 0 0 0 -2.2 0.16 2.92 2.92 0 0 0 -0.41 1.05 3.44 3.44 0 0 0 -2 0.16 3.37 3.37 0 0 0 -0.39 -1.22 1.5 1.5 0 0 0 -2.19 0.15 2.71 2.71 0 0 0 -0.37 0.89A3.61 3.61 0 0 0 6 1.89 3.29 3.29 0 0 0 5.68 0.76 1.23 1.23 0 0 0 4.43 0.38c-0.83 0.13 -1.15 0.71 -1.34 1.52a2.47 2.47 0 0 0 -1.63 0.26C0.92 2.51 0.9 3.27 0.75 3.75a21.37 21.37 0 0 0 -0.53 5.41c-0.2 4.9 -0.15 3.33 -0.22 7 0 2 0 4.53 2.15 4.69 3.5 0.26 0.13 0.39 8 -0.05a0.36 0.36 0 0 0 0 -0.71ZM13.69 2c0.09 -1.57 1 -1.18 1.1 -0.87a6.08 6.08 0 0 1 0 2.32c-0.17 0.57 -0.46 0.44 -1.06 0.42 0.07 -0.61 -0.09 -1.04 -0.04 -1.87Zm-4.81 -0.73c0.2 -0.41 0.81 -0.13 0.9 -0.16a6.58 6.58 0 0 1 0 2.5c-0.09 0.32 -0.28 0.54 -0.67 0.42l-0.39 0c0 -0.13 0.07 -0.27 0.07 -0.32a5.82 5.82 0 0 1 0.09 -2.44Zm-4.7 0.05c0.2 -0.42 0.81 -0.14 0.89 -0.17a6.6 6.6 0 0 1 0 2.51c-0.09 0.31 -0.28 0.54 -0.67 0.42H4c0 -0.12 0.07 -0.26 0.07 -0.31a5.65 5.65 0 0 1 0.11 -2.45Z"/>
                <path
                    d="M14.41 8.82a0.39 0.39 0 0 0 0.06 -0.57c-0.22 -0.32 -5.94 -0.8 -8.72 -0.63 -3.1 0.2 -2.69 0.73 -2.18 0.84 1.08 0.34 10.82 0.38 10.84 0.36Z"/>
                <path
                    d="M11.11 12.06c-0.33 -0.08 -2.88 -0.21 -3.36 -0.21s-4 0.1 -4.14 0.49a0.29 0.29 0 0 0 0.06 0.26 6.11 6.11 0 0 0 2 0.36 29.23 29.23 0 0 0 5.51 -0.14c0.39 0 0.52 -0.61 -0.07 -0.76Z"/>
                <path
                    d="M3.89 17a11.58 11.58 0 0 0 4.79 0 0.35 0.35 0 0 0 0 -0.49 9.27 9.27 0 0 0 -3 -0.45c-2.43 0.15 -2.88 0.68 -1.79 0.94Z"/>
            </g>
        </svg>
    );
}