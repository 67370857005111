/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 30/01/24
 * Time: 1:52 am
 * Project: landing_react
 */
import React, {memo} from 'react';

export const TechStackCard = memo(({data}) => {
    const {src, name} = data
    return (
        <div className="tech-stack-item">
            <img
                alt={name}
                loading="lazy"
                decoding="async"
                data-nimg="fill"
                className="tech-stack-item-image"
                style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    color: "transparent"
                }}
                src={src}
            />
        </div>
    );
})