/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 01/02/24
 * Time: 9:13 pm
 * Project: landing_react
 */
import React from 'react';

export default function Support2IconRaw(
    {
        strokeWidth = 3,
        className = "w-1",
        stroke = 'currentColor',
        fill = "none"
    }
) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3.5 30 30" className="kdx-detail-slot-icon">
            <g>
                <path
                    d="M17.11 2.81a2.34 2.34 0 0 0 -1.65 0A2.32 2.32 0 0 0 14.2 4a2 2 0 0 0 0 1.69 0.29 0.29 0 0 0 0.4 0.15 0.3 0.3 0 0 0 0.14 -0.4 1.26 1.26 0 0 1 0.15 -1.08 1.34 1.34 0 0 1 0.85 -0.61 1.38 1.38 0 0 1 0.89 0.11 1.12 1.12 0 0 1 0.59 0.58 1.57 1.57 0 0 1 0.14 0.95 1.17 1.17 0 0 1 -0.5 0.77c-0.21 0.14 -0.62 0.27 -0.88 0.45a1 1 0 0 0 -0.47 0.65 2.29 2.29 0 0 0 0 0.52 0.35 0.35 0 0 0 0.34 0.35 0.33 0.33 0 0 0 0.35 -0.33 1.35 1.35 0 0 1 0.05 -0.29 0.32 0.32 0 0 1 0.09 -0.17l1 -0.4a2.14 2.14 0 0 0 1.06 -1.29A2.61 2.61 0 0 0 18.32 4a2.16 2.16 0 0 0 -1.21 -1.19Z"/>
                <path
                    d="M15.71 8.86a0.87 0.87 0 0 0 -0.73 0.56 0.68 0.68 0 0 0 0.61 0.82 0.88 0.88 0 0 0 0.82 -0.57 0.74 0.74 0 0 0 -0.7 -0.81Z"/>
                <path
                    d="M5.41 11.57a8.63 8.63 0 0 0 -1.58 0.2 0.31 0.31 0 0 0 -0.28 0.33 0.3 0.3 0 0 0 0.33 0.27c0.65 0 1 0.14 1.85 0.13A11.3 11.3 0 0 0 7 12.33a0.34 0.34 0 1 0 0 -0.68c-0.36 0 -0.66 -0.06 -0.93 -0.07a4.24 4.24 0 0 0 -0.66 -0.01Z"/>
                <path
                    d="M3.67 16.07a0.32 0.32 0 0 0 0.29 0.31c0.66 0.09 1.38 0.14 2.1 0.17l1.36 0.05 1.33 0c1.07 -0.06 2.16 -0.21 3.09 -0.28a0.34 0.34 0 0 0 0 -0.68c-0.94 -0.06 -2 -0.14 -3.11 -0.17l-1.32 0 -3.41 0.31a0.3 0.3 0 0 0 -0.33 0.29Z"/>
                <path
                    d="M15.77 15.49a0.35 0.35 0 0 0 -0.35 0.34c0 0.77 -0.05 1.48 -0.09 2.06 0 0.37 -0.07 0.69 -0.11 0.92a1.33 1.33 0 0 1 -0.05 0.19 6.71 6.71 0 0 0 -0.79 0c-1.28 0 -3.38 -0.14 -4.38 -0.17a4.25 4.25 0 0 0 -0.63 0A0.52 0.52 0 0 0 9 19c-0.28 0.31 -0.58 1 -1.1 1.84a5.73 5.73 0 0 1 -0.78 1 3.48 3.48 0 0 1 -1.65 0.82A5.56 5.56 0 0 0 6 21a3.29 3.29 0 0 0 -0.22 -1.71 1 1 0 0 0 -0.55 -0.51 3.49 3.49 0 0 0 -1.06 -0.14l-2.75 0a2 2 0 0 1 -0.26 -0.07 4.6 4.6 0 0 0 0 -0.53C1 16.13 0.88 12 1 10.21c0 -0.28 0.13 -0.55 0.1 -0.64a7.58 7.58 0 0 1 1.73 -0.23c0.95 -0.07 2.1 -0.11 3.17 -0.13a0.29 0.29 0 0 0 0.3 -0.3 0.3 0.3 0 0 0 -0.3 -0.3c-1.3 0 -2.7 0 -3.75 0.05A4.25 4.25 0 0 0 0.45 9a1.18 1.18 0 0 0 -0.23 0.62A34.53 34.53 0 0 0 0 13.18l0 5.26 0 0.58a0.68 0.68 0 0 0 0.17 0.36 2.12 2.12 0 0 0 1.2 0.36c1.05 0.07 3.28 0.06 3.29 0.1a1.72 1.72 0 0 1 0 0.24 3.67 3.67 0 0 1 -0.12 1.57A3.47 3.47 0 0 1 4 23a0.53 0.53 0 0 0 -0.12 0.46 0.54 0.54 0 0 0 0.46 0.47 1.65 1.65 0 0 0 0.48 0 5.56 5.56 0 0 0 3.1 -1.36 6.72 6.72 0 0 0 0.9 -1.18c0.39 -0.65 0.62 -1.25 0.84 -1.6a1.6 1.6 0 0 1 0.23 0c0.94 0 3 0.07 4.39 0a4.92 4.92 0 0 0 1.2 -0.12 0.65 0.65 0 0 0 0.28 -0.15 1.85 1.85 0 0 0 0.26 -0.89c0.07 -0.65 0.1 -1.67 0.1 -2.81a0.35 0.35 0 0 0 -0.35 -0.33Z"/>
                <path
                    d="M24 6.71a7.21 7.21 0 0 0 -2.75 -4.93A8.52 8.52 0 0 0 15.89 0a8.28 8.28 0 0 0 -5 1.7 6.71 6.71 0 0 0 -2.81 4.5A6.13 6.13 0 0 0 9 10a5.52 5.52 0 0 0 3.22 2.21 6.91 6.91 0 0 0 1.15 0.18c0.63 0.06 1.23 0.08 1.53 0.08 0.22 0.29 0.59 0.92 1 1.43a3.07 3.07 0 0 0 0.65 0.61 6.38 6.38 0 0 0 2.66 0.79h0.35a0.44 0.44 0 0 0 0.31 -0.21 0.38 0.38 0 0 0 -0.05 -0.48 2.31 2.31 0 0 1 -0.48 -0.95 2.55 2.55 0 0 1 0 -1.24A8.45 8.45 0 0 0 23 10.69c0.74 -0.8 1.1 -1.95 1 -3.98ZM22.25 10a7.85 7.85 0 0 1 -3.16 1.58 1.57 1.57 0 0 0 -0.63 1.6 3.71 3.71 0 0 0 0.34 1.25 4.81 4.81 0 0 1 -1.8 -0.54 3.22 3.22 0 0 1 -0.68 -0.66c-0.75 -0.92 -0.85 -1.37 -1.22 -1.37 0 0 -0.79 0 -1.65 -0.11a8.65 8.65 0 0 1 -1.06 -0.2 4.78 4.78 0 0 1 -2.72 -2A5.36 5.36 0 0 1 9 6.25a5.79 5.79 0 0 1 2.54 -3.66 7.3 7.3 0 0 1 4.36 -1.4 7.39 7.39 0 0 1 4.62 1.5 6.08 6.08 0 0 1 2.39 4.1c0.09 1.62 -0.06 2.57 -0.66 3.21Z"/>
            </g>
        </svg>
    );
}