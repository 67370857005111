import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {gsap} from "gsap/all";
import {
    AmbientLight,
    DirectionalLight,
    Fog,
    Mesh,
    MeshPhysicalMaterial,
    MeshStandardMaterial,
    Object3D,
    PCFSoftShadowMap,
    PerspectiveCamera,
    PlaneGeometry,
    Scene,
    SpotLight,
    WebGLRenderer
} from "three";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";

export default class AnimatedBG {
    init(loadAt, callback, errorCallback) {
        try {
            this.loadAt = loadAt;
            this.callback = callback;
            this.errorCallback = errorCallback;
            this.group = new Object3D();
            this.bgColor = window.getComputedStyle(document.body, null).getPropertyValue('background-color');
            this.gridSize = 40;
            this.buildings = [];
            this.fogConfig = {
                color: '#000',
                near: 1,
                far: 300
            };

            this.width = window.innerWidth;
            this.height = window.innerHeight;

            this.createScene();
            this.createCamera();
            this.addAmbientLight();
            this.addSpotLight();
            this.addCameraControls();
            this.addFloor();
            this.addBackgroundShape();
            this.loadModels('https://ik.imagekit.io/kittydev/obj/hero_section_bg_Eo1i8FtKv.obj', this.onLoadModelsComplete.bind(this));

            this.animate();

            this.pointLightFront = {
                color: '#ec0808',
                intensity: 0,
                position: {
                    x: -15,
                    y: 29,
                    z: 43,
                }
            };

            this.addPointLight(this.pointLightFront, 'Front light');

            this.pointLightTop = {
                color: '#a18d8d',
                intensity: 100,
                position: {
                    x: -7,
                    y: 100,
                    z: -100,
                }
            };

            this.addPointLight(this.pointLightTop, 'Top light');

            this.pointLightLeft = {
                color: '#271c41',
                intensity: 0,
                position: {
                    x: -30,
                    y: -20,
                    z: -51,
                }
            };

            this.addPointLight(this.pointLightLeft, 'Left light');

            this.pointLightObj3 = {
                color: '#9900ff',
                intensity: 0,
                position: {
                    x: 5,
                    y: 53,
                    z: -13,
                }
            };

            this.addPointLight(this.pointLightObj3, 'Fourth light');
        } catch (e) {
            errorCallback()
        }
    }

    addPointLight(params, name) {
        const pointLight = new DirectionalLight(params.color, params.intensity);
        pointLight.position.set(params.position.x, params.position.y, params.position.z);
        this.scene.add(pointLight);
    }

    getRandomBuilding() {
        return this.models[Math.floor(Math.random() * Math.floor(this.models.length))];
    }

    onLoadModelsComplete(obj, callback) {
        this.models = [...obj.children].map((model) => {
            const scale = .01;
            model.scale.set(scale, scale, scale);
            model.position.set(0, -14, 0);
            model.receiveShadow = true;
            model.castShadow = true;

            return model;
        });
        setTimeout(() => {
            this.callback()
            this.showBuildings();
            window.addEventListener('resize', this.onResize.bind(this));
        }, 500);

        this.draw();
    }

    draw() {
        const boxSize = 3;
        const meshParams = {
            color: '#161616',
            metalness: .69,
            emissive: '#000000',
            roughness: .8,
        };

        const max = .009;
        const min = .001;

        const material = new MeshPhysicalMaterial(meshParams);
        for (let i = 0; i < this.gridSize; i++) {
            for (let j = 0; j < this.gridSize; j++) {
                const building = this.getRandomBuilding().clone();

                building.material = material;
                building.scale.y = Math.random() * (max - min + .01);
                building.position.x = (i * boxSize);
                building.position.z = (j * boxSize);
                this.group.add(building);
                this.buildings.push(building);
            }
        }

        this.scene.add(this.group);
        this.group.position.set(-this.gridSize - 10, 1, -this.gridSize - 10);
    }

    showBuildings() {
        this.sortBuildingsByDistance();

        this.buildings.forEach((building, index) => {
            gsap.to(building.position, {
                y: 1,
                duration: .6 + (index / 4000),
                ease: 'expo.out',
                delay: index / 4000
            });
        });
    }

    sortBuildingsByDistance() {
        this.buildings.sort((a, b) => {
            if (a.position.z > b.position.z) {
                return 1;
            }
            if (a.position.z < b.position.z) {
                return -1;
            }
            return 0;
        }).reverse();
    }

    loadModels(name, callback) {
        const objLoader = new OBJLoader();

        objLoader.load(name, callback);
    }

    onResize() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;

        this.camera.aspect = this.width / this.height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.width, this.height);
    }

    createScene() {
        this.scene = new Scene();

        this.renderer = new WebGLRenderer({antialias: true, alpha: true});
        this.renderer.setSize(this.width, this.height);

        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = PCFSoftShadowMap;
        document.body.querySelector('.' + this.loadAt).appendChild(this.renderer.domElement);
        this.scene.fog = new Fog(this.fogConfig.color, this.fogConfig.near, this.fogConfig.far);
    }

    createCamera() {
        this.camera = new PerspectiveCamera(20, this.width / this.height, 1, 1000);
        this.camera.position.set(3, 50, 155);

        this.scene.add(this.camera);
    }

    addCameraControls() {
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enabled = false;
    }

    addSpotLight() {
        const light = {color: '#b1b1b1', x: 100, y: 150, z: 100};
        const spotLight = new SpotLight(light.color, 2);
        spotLight.position.set(light.x, light.y, light.z);
        spotLight.castShadow = true;
        this.scene.add(spotLight);
    }


    addAmbientLight() {
        const light = {color: '#fff'};
        const ambientLight = new AmbientLight(light.color);
        this.scene.add(ambientLight);
    }

    addBackgroundShape() {
        const planeGeometry = new PlaneGeometry(400, 100);
        const planeMaterial = new MeshPhysicalMaterial({color: '#fff'});
        this.backgroundShape = new Mesh(planeGeometry, planeMaterial);

        this.backgroundShape.position.y = 10;
        this.backgroundShape.position.z = -150;

        this.scene.add(this.backgroundShape);

        this.mouseX = 3;
        this.mouseY = 50;
        this.lastMouseX = 3;
        this.lastMouseY = 50;

        const mouseMoveHandler = (ev) => {
            if (window.scrollY < 500) {
                this.mouseX = ev.pageX;
                this.mouseY = ev.pageY;
            }
        };
        window.addEventListener('mousemove', mouseMoveHandler);
        window.addEventListener('scroll', () => {
            if (window.scrollY >= 500) {
                window.removeEventListener('mousemove', mouseMoveHandler);
            } else {
                window.addEventListener('mousemove', mouseMoveHandler);
            }
        });
    }

    tilt() {
        const lerp = (a, b, n) => (1 - n) * a + n * b;
        const lineEq = (y2, y1, x2, x1, currentVal) => {
            let m = (y2 - y1) / (x2 - x1);
            let b = y1 - m * x1;

            return m * currentVal + b;
        };

        this.lastMouseX = lerp(this.lastMouseX, lineEq(0, 6, this.width, 0, this.mouseX), 0.05);
        this.lastMouseY = lerp(this.lastMouseY, lineEq(48, 52, this.height, 0, this.mouseY), 0.05);
        this.camera.position.set(this.lastMouseX, this.lastMouseY, 155);
    }

    addFloor() {
        const floor = {color: '#000'};
        const planeGeometry = new PlaneGeometry(200, 200);
        const planeMaterial = new MeshStandardMaterial({
            color: floor.color,
            metalness: 0,
            emissive: '#000000',
            roughness: 0,
        });

        const plane = new Mesh(planeGeometry, planeMaterial);
        planeGeometry.rotateX(-Math.PI / 2);
        plane.position.y = 0;
        this.scene.add(plane);
    }

    animate() {
        this.tilt();

        this.controls.update();

        this.renderer.render(this.scene, this.camera);

        requestAnimationFrame(this.animate.bind(this));
    }

    hexToRgbTreeJs(hex) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16) / 255,
            g: parseInt(result[2], 16) / 255,
            b: parseInt(result[3], 16) / 255
        } : null;
    }

}
