/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 17/04/24
 * Time: 10:26 PM
 * Project: kittydev_landing_site_react
 */

import React, {memo, useEffect, useState} from 'react';

export const Header = memo(({limit = 444, speed = 8}) => {
    const initValue = {
        fullNavTop: -55.5,
        fullNavWidth: 10
    }
    const [uiScrollStyle, setUiScrollStyle] = useState({...initValue})
    useEffect(() => {
        window.addEventListener('scroll', (event) => {
            const pageY = window.scrollY;

            if (pageY <= limit) {
                setUiScrollStyle(old => ({
                    ...old,
                    fullNavTop: (initValue.fullNavTop + (pageY / speed))
                }));
            } else {
                if (uiScrollStyle.fullNavTop < 100) {
                    setUiScrollStyle(old => ({
                        ...old,
                        fullNavTop: 0
                    }));
                }
            }
        })
    }, [uiScrollStyle]);
    const handleClick = () => {
        window.launchWhatsAppSignup();
    };

    return (
        <nav className="navigation-container">
            <div className="navigation-container-mobile"
                 style={{top: `${uiScrollStyle.fullNavTop}px`}}>
                <div className="navigation-holder-mobile">
                    {/*            Mobile */}
                    <a href="/">
                        {/*<img*/}
                        {/*    className="navigation-mobile-logo"*/}
                        {/*src={"https://ik.imagekit.io/kittydev/KittXDev/KittXdev_x_white_icon_uOCkfhTIz.svg"}*/}
                        {/*/>*/}
                        <svg className="navigation-mobile-logo" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 849.4 157.08">
                            <path
                                d="m33.64,35.21v44.98h19.9l37.71-44.98h30.45v6.92l-42.73,51.04,42.73,51.04v6.92h-30.45l-36.33-43.25h-21.28v43.25H2.5V35.21h31.14Z"/>
                            <path
                                d="m142.63,34.34c0-3.57,1.04-6.63,3.11-9.17,2.08-2.54,6-3.81,11.76-3.81h1.38c5.77,0,9.69,1.27,11.76,3.81,2.08,2.54,3.11,5.6,3.11,9.17v2.08c0,3.58-1.04,6.63-3.11,9.17-2.08,2.54-6,3.81-11.76,3.81h-1.38c-5.77,0-9.69-1.27-11.76-3.81-2.08-2.54-3.11-5.59-3.11-9.17v-2.08Zm1.73,30.27v86.5h27.68v-74.39l-20.76-12.11h-6.92Z"/>
                            <path
                                d="m224.97,64.62h26.64v24.22h-26.64v27.68c0,1.96.11,3.69.35,5.19.23,1.5.87,2.77,1.9,3.81s2.65,1.82,4.84,2.34c2.19.52,5.25.78,9.17.78,2.65,0,5.42-.14,8.3-.43,2.88-.29,5.48-.78,7.79-1.47v25.26c-2.31.58-4.96,1.01-7.96,1.3-3,.29-6.11.43-9.34.43-7.84,0-14.48-.75-19.9-2.25-5.42-1.5-9.8-3.66-13.15-6.49-3.35-2.82-5.8-6.34-7.35-10.55-1.56-4.21-2.34-9.02-2.34-14.45V30.02h6.92l20.76,12.11v22.49Z"/>
                            <path
                                d="m307.32,64.62h26.64v24.22h-26.64v27.68c0,1.96.11,3.69.35,5.19.23,1.5.87,2.77,1.9,3.81s2.65,1.82,4.84,2.34c2.19.52,5.25.78,9.17.78,2.65,0,5.42-.14,8.3-.43,2.88-.29,5.48-.78,7.79-1.47v25.26c-2.31.58-4.96,1.01-7.96,1.3-3,.29-6.11.43-9.34.43-7.84,0-14.48-.75-19.9-2.25-5.42-1.5-9.8-3.66-13.15-6.49-3.35-2.82-5.8-6.34-7.35-10.55-1.56-4.21-2.34-9.02-2.34-14.45V30.02h6.92l20.76,12.11v22.49Z"/>
                            <path
                                d="m399.01,93.16l-42.73-51.04v-6.92h30.62l30.28,36.33,30.45-36.33h30.45v6.92l-42.73,51.04,42.73,51.04v6.92h-30.45l-30.45-36.16-30.28,36.16h-30.62v-6.92l42.73-51.04Z"/>
                            <path
                                d="m600.55,151.12h-6.92l-9.34-5.36c-5.54,3-11.42,5.22-17.65,6.66-6.23,1.44-13.15,2.16-20.76,2.16-9.69,0-17.71-1.12-24.05-3.37-6.34-2.25-11.42-5.33-15.22-9.25-3.81-3.92-6.46-8.5-7.96-13.75-1.5-5.25-2.25-10.87-2.25-16.87v-6.92c0-6,.75-11.62,2.25-16.87,1.5-5.25,4.15-9.83,7.96-13.75,3.81-3.92,8.88-7.01,15.22-9.26,6.34-2.25,14.36-3.37,24.05-3.37,5.07,0,9.83.35,14.27,1.04,4.44.69,8.68,1.67,12.71,2.94V21.37h6.92l20.76,12.11v117.64Zm-53.11-21.62c9.11,0,17.59-1.21,25.43-3.63v-35.81c-3.92-1.27-7.96-2.22-12.11-2.85-4.15-.63-8.59-.95-13.32-.95-5.88,0-10.38.61-13.49,1.82s-5.42,2.8-6.92,4.76c-1.5,1.96-2.37,4.27-2.59,6.92-.23,2.65-.35,5.36-.35,8.13s.11,5.48.35,8.13c.23,2.65,1.09,4.96,2.59,6.92,1.5,1.96,3.81,3.55,6.92,4.76s7.61,1.82,13.49,1.82Z"/>
                            <path
                                d="m673.55,154.58c-9.69,0-17.71-1.12-24.05-3.37-6.34-2.25-11.42-5.33-15.22-9.25-3.81-3.92-6.46-8.5-7.96-13.75-1.5-5.25-2.25-10.87-2.25-16.87v-6.92c0-6,.75-11.62,2.25-16.87,1.5-5.25,4.15-9.83,7.96-13.75,3.81-3.92,8.88-7.01,15.22-9.26,6.34-2.25,14.36-3.37,24.05-3.37h4.84c9.8,0,17.82.72,24.05,2.16,6.23,1.44,11.07,3.43,14.53,5.97,3.46,2.54,5.82,5.48,7.09,8.82,1.27,3.35,1.9,6.92,1.9,10.73v1.73c0,3.46-.43,6.75-1.3,9.86-.87,3.11-2.91,5.88-6.14,8.3-3.23,2.42-7.99,4.32-14.27,5.71-6.29,1.38-14.85,2.08-25.69,2.08-5.19,0-9.98-.23-14.36-.69-4.38-.46-8.54-1.27-12.46-2.42.11,2.42.52,4.64,1.21,6.66.69,2.02,1.99,3.72,3.89,5.1,1.9,1.38,4.58,2.45,8.04,3.2,3.46.75,8.07,1.12,13.84,1.12,2.77,0,5.85-.11,9.26-.35,3.4-.23,6.92-.55,10.55-.95,3.63-.4,7.21-.92,10.73-1.56,3.52-.63,6.77-1.41,9.78-2.34v25.95c-3,.69-6.26,1.3-9.78,1.82-3.52.52-7.15.98-10.9,1.38-3.75.4-7.47.69-11.16.87-3.69.17-7.15.26-10.38.26h-3.29Zm5.02-71.79c-4.61,0-8.48.26-11.59.78s-5.65,1.3-7.61,2.34c-1.96,1.04-3.46,2.31-4.5,3.81-1.04,1.5-1.79,3.23-2.25,5.19,3.34.92,6.95,1.59,10.81,1.99,3.86.41,8.33.61,13.41.61s9.05-.2,11.94-.61c2.88-.4,5.02-.92,6.4-1.56,1.38-.63,2.25-1.32,2.59-2.08.35-.75.52-1.53.52-2.34s-.15-1.7-.43-2.68c-.29-.98-1.1-1.87-2.42-2.68-1.33-.81-3.32-1.47-5.97-1.99-2.66-.52-6.29-.78-10.9-.78Z"/>
                            <path
                                d="m767.49,64.62l27.51,57.09,27.68-57.09h24.22v6.92l-39.62,79.58h-24.39l-39.62-79.58v-6.92h24.22Z"/>
                        </svg>
                    </a>
                </div>
            </div>
            <div className="navigation-container-pc">
                <div className="navigation-holder-pc pt-m">
                    <a href="/">
                        <img
                            className="navigation-pc-logo"
                            src={"https://ik.imagekit.io/kittydev/KittXDev/KittXdev_x_white_icon_uOCkfhTIz.svg"}
                        />
                        {/*<svg className="navigation-pc-logo" xmlns="http://www.w3.org/2000/svg"*/}
                        {/*     viewBox="0 0 849.4 157.08">*/}
                        {/*    <path*/}
                        {/*        d="m33.64,35.21v44.98h19.9l37.71-44.98h30.45v6.92l-42.73,51.04,42.73,51.04v6.92h-30.45l-36.33-43.25h-21.28v43.25H2.5V35.21h31.14Z"/>*/}
                        {/*    <path*/}
                        {/*        d="m142.63,34.34c0-3.57,1.04-6.63,3.11-9.17,2.08-2.54,6-3.81,11.76-3.81h1.38c5.77,0,9.69,1.27,11.76,3.81,2.08,2.54,3.11,5.6,3.11,9.17v2.08c0,3.58-1.04,6.63-3.11,9.17-2.08,2.54-6,3.81-11.76,3.81h-1.38c-5.77,0-9.69-1.27-11.76-3.81-2.08-2.54-3.11-5.59-3.11-9.17v-2.08Zm1.73,30.27v86.5h27.68v-74.39l-20.76-12.11h-6.92Z"/>*/}
                        {/*    <path*/}
                        {/*        d="m224.97,64.62h26.64v24.22h-26.64v27.68c0,1.96.11,3.69.35,5.19.23,1.5.87,2.77,1.9,3.81s2.65,1.82,4.84,2.34c2.19.52,5.25.78,9.17.78,2.65,0,5.42-.14,8.3-.43,2.88-.29,5.48-.78,7.79-1.47v25.26c-2.31.58-4.96,1.01-7.96,1.3-3,.29-6.11.43-9.34.43-7.84,0-14.48-.75-19.9-2.25-5.42-1.5-9.8-3.66-13.15-6.49-3.35-2.82-5.8-6.34-7.35-10.55-1.56-4.21-2.34-9.02-2.34-14.45V30.02h6.92l20.76,12.11v22.49Z"/>*/}
                        {/*    <path*/}
                        {/*        d="m307.32,64.62h26.64v24.22h-26.64v27.68c0,1.96.11,3.69.35,5.19.23,1.5.87,2.77,1.9,3.81s2.65,1.82,4.84,2.34c2.19.52,5.25.78,9.17.78,2.65,0,5.42-.14,8.3-.43,2.88-.29,5.48-.78,7.79-1.47v25.26c-2.31.58-4.96,1.01-7.96,1.3-3,.29-6.11.43-9.34.43-7.84,0-14.48-.75-19.9-2.25-5.42-1.5-9.8-3.66-13.15-6.49-3.35-2.82-5.8-6.34-7.35-10.55-1.56-4.21-2.34-9.02-2.34-14.45V30.02h6.92l20.76,12.11v22.49Z"/>*/}
                        {/*    <path*/}
                        {/*        d="m399.01,93.16l-42.73-51.04v-6.92h30.62l30.28,36.33,30.45-36.33h30.45v6.92l-42.73,51.04,42.73,51.04v6.92h-30.45l-30.45-36.16-30.28,36.16h-30.62v-6.92l42.73-51.04Z"/>*/}
                        {/*    <path*/}
                        {/*        d="m600.55,151.12h-6.92l-9.34-5.36c-5.54,3-11.42,5.22-17.65,6.66-6.23,1.44-13.15,2.16-20.76,2.16-9.69,0-17.71-1.12-24.05-3.37-6.34-2.25-11.42-5.33-15.22-9.25-3.81-3.92-6.46-8.5-7.96-13.75-1.5-5.25-2.25-10.87-2.25-16.87v-6.92c0-6,.75-11.62,2.25-16.87,1.5-5.25,4.15-9.83,7.96-13.75,3.81-3.92,8.88-7.01,15.22-9.26,6.34-2.25,14.36-3.37,24.05-3.37,5.07,0,9.83.35,14.27,1.04,4.44.69,8.68,1.67,12.71,2.94V21.37h6.92l20.76,12.11v117.64Zm-53.11-21.62c9.11,0,17.59-1.21,25.43-3.63v-35.81c-3.92-1.27-7.96-2.22-12.11-2.85-4.15-.63-8.59-.95-13.32-.95-5.88,0-10.38.61-13.49,1.82s-5.42,2.8-6.92,4.76c-1.5,1.96-2.37,4.27-2.59,6.92-.23,2.65-.35,5.36-.35,8.13s.11,5.48.35,8.13c.23,2.65,1.09,4.96,2.59,6.92,1.5,1.96,3.81,3.55,6.92,4.76s7.61,1.82,13.49,1.82Z"/>*/}
                        {/*    <path*/}
                        {/*        d="m673.55,154.58c-9.69,0-17.71-1.12-24.05-3.37-6.34-2.25-11.42-5.33-15.22-9.25-3.81-3.92-6.46-8.5-7.96-13.75-1.5-5.25-2.25-10.87-2.25-16.87v-6.92c0-6,.75-11.62,2.25-16.87,1.5-5.25,4.15-9.83,7.96-13.75,3.81-3.92,8.88-7.01,15.22-9.26,6.34-2.25,14.36-3.37,24.05-3.37h4.84c9.8,0,17.82.72,24.05,2.16,6.23,1.44,11.07,3.43,14.53,5.97,3.46,2.54,5.82,5.48,7.09,8.82,1.27,3.35,1.9,6.92,1.9,10.73v1.73c0,3.46-.43,6.75-1.3,9.86-.87,3.11-2.91,5.88-6.14,8.3-3.23,2.42-7.99,4.32-14.27,5.71-6.29,1.38-14.85,2.08-25.69,2.08-5.19,0-9.98-.23-14.36-.69-4.38-.46-8.54-1.27-12.46-2.42.11,2.42.52,4.64,1.21,6.66.69,2.02,1.99,3.72,3.89,5.1,1.9,1.38,4.58,2.45,8.04,3.2,3.46.75,8.07,1.12,13.84,1.12,2.77,0,5.85-.11,9.26-.35,3.4-.23,6.92-.55,10.55-.95,3.63-.4,7.21-.92,10.73-1.56,3.52-.63,6.77-1.41,9.78-2.34v25.95c-3,.69-6.26,1.3-9.78,1.82-3.52.52-7.15.98-10.9,1.38-3.75.4-7.47.69-11.16.87-3.69.17-7.15.26-10.38.26h-3.29Zm5.02-71.79c-4.61,0-8.48.26-11.59.78s-5.65,1.3-7.61,2.34c-1.96,1.04-3.46,2.31-4.5,3.81-1.04,1.5-1.79,3.23-2.25,5.19,3.34.92,6.95,1.59,10.81,1.99,3.86.41,8.33.61,13.41.61s9.05-.2,11.94-.61c2.88-.4,5.02-.92,6.4-1.56,1.38-.63,2.25-1.32,2.59-2.08.35-.75.52-1.53.52-2.34s-.15-1.7-.43-2.68c-.29-.98-1.1-1.87-2.42-2.68-1.33-.81-3.32-1.47-5.97-1.99-2.66-.52-6.29-.78-10.9-.78Z"/>*/}
                        {/*    <path*/}
                        {/*        d="m767.49,64.62l27.51,57.09,27.68-57.09h24.22v6.92l-39.62,79.58h-24.39l-39.62-79.58v-6.92h24.22Z"/>*/}
                        {/*</svg>*/}
                    </a>
                </div>
            </div>
        </nav>
    );
})