/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 17/04/24
 * Time: 10:00 PM
 * Project: kittydev_landing_site_react
 */
import React, {memo} from 'react';
import {CONTACT_EMAIL} from "../../LANDING_DATA";
import {Header} from "../../Components/Header";
import {Footer} from "../../Components/Footer";

export const PrivacyAndPolicy = memo(() => {
    return (
        <>
            <Header speed={2} limit={100}/>
            <div className={"listing-header"}>
                <h1 className="hero-heading-text text-shadow">
                    Privacy Policy
                </h1>
            </div>
            <div className={"list-container"}>

                <div className={"list-holder"}>
                    <div>
                        <p className={"list-heading"}>
                            1. Information that Identifies You
                        </p>
                        <p className={"list-values"}>
                            Some visitors to Kittxdev websites opt to engage with us in ways that necessitate
                            Kittxdev to collect information that identifies them personally. The extent and nature of
                            the
                            information Kittxdev collects depend on the nature of the interaction.
                        </p>
                    </div>

                    <div>
                        <p className={"list-heading"}>
                            2. Protection Measures
                        </p>
                        <p className={"list-values"}>
                            Safeguarding your Personal Information is a priority for us. However, it's important to note
                            that no
                            method
                            of transmission over the Internet, or method of electronic storage, is completely secure.
                            While
                            we
                            endeavor
                            to employ commercially reasonable methods to protect your Personal Information, we cannot
                            guarantee
                            absolute
                            security.
                        </p>
                    </div>


                    <div>
                        <p className={"list-heading"}>3. Online Transactions</p>
                        <p className={"list-values"}>
                            Individuals who conduct transactions with Kittxdev – such as purchasing Kittxdev's services
                            or
                            products
                            –
                            may be required to furnish additional details, including personal and financial information
                            necessary to facilitate those transactions. In each instance, Kittxdev only collects such
                            information to
                            the
                            extent that it's necessary or appropriate to fulfill the purpose of the visitor's
                            interaction
                            with
                            Kittxdev.
                            Kittxdev does not disclose personally-identifying information except as outlined below.
                            Furthermore,
                            visitors can always opt not to provide personally-identifying information, although doing so
                            may
                            preclude
                            them from participating in certain website-related activities.
                        </p>
                    </div>


                    <div>
                        <p className={"list-heading"}>
                            4. Updates to Privacy Policy
                        </p>
                        <p className={"list-values"}>
                            While most revisions are likely to be minor, Kittxdev reserves the right to modify its
                            Privacy
                            Policy
                            periodically, at Kittxdev's discretion. Kittxdev encourages visitors to review this page
                            regularly
                            for any updates to its Privacy Policy. Your continued use of this site following any changes
                            to
                            this
                            Privacy
                            Policy will signify your acceptance of such changes.
                        </p>
                    </div>


                    <div>
                        <p className={"list-heading"}>
                            5. Contact Information & Acknowledgment
                        </p>
                        <p className={"list-values"}>
                            This privacy policy was generated using the privacy policy generator available at
                            kittxdev.com.
                            If
                            you
                            have
                            any inquiries regarding our Privacy Policy, please reach out to us via email or phone at
                            <a href={`mailto:${CONTACT_EMAIL}`}> contact@kittxdev.com.</a>
                        </p>
                    </div>

                </div>

            </div>
            <Footer/>
        </>
    );
})