/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 30/01/24
 * Time: 1:32 am
 * Project: landing_react
 */
import React from 'react';

export default function CustomSoftIconRaw() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3.5 30 30" className="kdx-detail-slot-icon">
            <g>
                <path
                    d="M4.75 10.16a5.11 5.11 0 0 0 2 -0.38l0.91 0.9a0.29 0.29 0 0 0 0.42 0 0.3 0.3 0 0 0 0 -0.42L7 9.17a0.32 0.32 0 0 0 -0.37 -0.07 4.21 4.21 0 0 1 -1.89 0.33 5.81 5.81 0 0 1 -1.89 -0.38 3.12 3.12 0 0 1 -1.6 -2.14 4.23 4.23 0 0 1 0 -2.12c0.58 0.47 1.44 1.21 1.86 1.57a1.32 1.32 0 0 0 0.59 0.31c0.36 0 1.64 0.15 2.17 0.17a1.53 1.53 0 0 0 0.34 0 0.58 0.58 0 0 0 0.47 -0.38 2.38 2.38 0 0 0 0.05 -0.69c0 -0.6 -0.16 -1.54 -0.16 -1.86a1.32 1.32 0 0 0 -0.33 -0.62c-0.37 -0.39 -1.16 -1.2 -1.68 -1.71A6.13 6.13 0 0 1 5.83 1a1.27 1.27 0 0 1 0.88 0 4.84 4.84 0 0 1 1.48 1.21 5.13 5.13 0 0 1 0.92 1.7 3.06 3.06 0 0 1 0.14 1.43 4.32 4.32 0 0 1 -0.48 1.43 0.36 0.36 0 0 0 0.06 0.42L10 8.37a0.34 0.34 0 0 0 0.49 -0.47l-1 -1A4.59 4.59 0 0 0 10 5.46a4 4 0 0 0 -0.1 -1.81 6.19 6.19 0 0 0 -1 -2A5.8 5.8 0 0 0 7.18 0.18C6.41 -0.27 4.92 0.22 4 0.66a5 5 0 0 0 -0.57 0.34 0.58 0.58 0 0 0 -0.13 0.1 0.49 0.49 0 0 0 -0.09 0.64c0.09 0.17 0.9 0.84 2.19 2.3l0.06 0.07 0.11 1.57c-0.52 0 -1.26 -0.11 -1.66 -0.13l-0.09 -0.07c-0.5 -0.4 -1.64 -1.36 -2.18 -1.76a1.92 1.92 0 0 0 -0.35 -0.23 0.51 0.51 0 0 0 -0.73 0.22 4.8 4.8 0 0 0 -0.19 3.45A4 4 0 0 0 2.56 9.8a6.6 6.6 0 0 0 2.19 0.36Z"/>
                <path
                    d="M21 14.29a6.59 6.59 0 0 0 -2.29 -0.41 5 5 0 0 0 -2.06 0.4l-1.08 -1.11a0.3 0.3 0 0 0 -0.42 0 0.31 0.31 0 0 0 0 0.43l1.2 1.33a0.34 0.34 0 0 0 0.39 0.07 4 4 0 0 1 2 -0.36 5.66 5.66 0 0 1 2 0.43 3.1 3.1 0 0 1 1.54 2.18 4 4 0 0 1 0 2.1c-0.55 -0.49 -1.39 -1.25 -1.8 -1.6a1.29 1.29 0 0 0 -0.58 -0.33c-0.36 0 -1.64 -0.19 -2.17 -0.22h-0.34a0.58 0.58 0 0 0 -0.48 0.37 2.29 2.29 0 0 0 -0.06 0.69c0 0.59 0.12 1.54 0.11 1.86a1.25 1.25 0 0 0 0.32 0.62c0.35 0.41 1.12 1.23 1.64 1.76a5.77 5.77 0 0 1 -1.29 0.49 1.26 1.26 0 0 1 -0.88 0 5 5 0 0 1 -1.46 -1.23A5.4 5.4 0 0 1 14.25 20a3.16 3.16 0 0 1 -0.08 -1.52 4.35 4.35 0 0 1 0.58 -1.48 0.35 0.35 0 0 0 -0.07 -0.45l-1 -1a0.34 0.34 0 0 0 -0.48 0 0.34 0.34 0 0 0 0 0.48l0.83 0.8a5 5 0 0 0 -0.58 1.46 4.08 4.08 0 0 0 0.05 1.9 6.4 6.4 0 0 0 1 2 5.91 5.91 0 0 0 1.6 1.61c0.76 0.47 2.26 0 3.15 -0.4 0.29 -0.14 0.51 -0.26 0.61 -0.32A0.73 0.73 0 0 0 20 23a0.49 0.49 0 0 0 0.1 -0.63S18.76 20.93 18 20l0 -0.07 -0.08 -1.57c0.52 0 1.26 0.13 1.66 0.16l0.08 0.07c0.5 0.42 1.61 1.41 2.14 1.83a3.43 3.43 0 0 0 0.34 0.24 0.53 0.53 0 0 0 0.75 -0.21 4.84 4.84 0 0 0 0.2 -3.45A4 4 0 0 0 21 14.29Z"/>
                <path
                    d="M23.62 6.55a21 21 0 0 0 -2.85 -3A24.29 24.29 0 0 0 17.55 1a1.15 1.15 0 0 0 -0.67 -0.2 0.51 0.51 0 0 0 -0.33 0.18 1.68 1.68 0 0 1 -1.63 0.69 3.24 3.24 0 0 1 -1.75 -1 0.44 0.44 0 0 0 -0.6 0 7.94 7.94 0 0 0 -1.1 1.17 3.59 3.59 0 0 0 -0.25 0.34 0.29 0.29 0 0 0 0.07 0.41 0.27 0.27 0 0 0 0.36 0 9.92 9.92 0 0 1 1.19 -1.28 3.83 3.83 0 0 0 1.94 1.17 2.43 2.43 0 0 0 2.22 -0.7 3.36 3.36 0 0 1 0.4 0.22A39.54 39.54 0 0 1 22 6.34c0.26 0.29 0.6 0.59 0.74 0.79a14.38 14.38 0 0 1 -1.54 2.1 6.29 6.29 0 0 1 -0.74 0.73 1.22 1.22 0 0 1 -0.32 0.22 9.91 9.91 0 0 1 -1.34 -0.94c-1.8 -1.4 -4.58 -3.83 -5.93 -5 -0.95 -0.85 -0.78 -0.72 -0.84 -0.76s-0.71 0.24 -0.35 0.59l0.71 0.69c0.44 0.43 1 1 1.73 1.64l-0.08 0L3.68 17.16C1.91 19 2 20.61 2.68 21.52a3 3 0 0 0 4.47 -0.11c1.08 -1.19 2.67 -3 4.3 -4.94 2.34 -2.76 4.76 -5.69 5.93 -7.12 0.6 0.52 1.14 1 1.57 1.3a2.81 2.81 0 0 0 1.14 0.6 2.73 2.73 0 0 0 1.58 -0.91 14.54 14.54 0 0 0 2.14 -2.83 1 1 0 0 0 -0.19 -0.96Zm-11.79 8.09c-2 2.27 -4.16 4.59 -5.51 6a1.81 1.81 0 0 1 -2.73 0.19c-0.48 -0.62 -0.3 -1.63 0.89 -2.91l10 -11 0.05 -0.08c0.77 0.71 1.59 1.46 2.35 2.14 -0.99 1.13 -2.98 3.36 -5.05 5.66Z"/>
            </g>
        </svg>
    );
}