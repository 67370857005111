/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 01/02/24
 * Time: 8:46 pm
 * Project: landing_react
 */
import React from 'react';

export default function SupportIconRaw() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3.5 30 30" className="kdx-detail-slot-icon">
            <g>
                <path
                    d="M15.85 4.86a0.32 0.32 0 0 0 -0.36 0.31c-0.06 0.29 -0.2 0.9 -0.29 1.62a5.06 5.06 0 0 0 -0.06 0.69 5.45 5.45 0 0 0 0 0.7 14.78 14.78 0 0 0 0.22 1.63 0.29 0.29 0 0 0 0.3 0.3 0.3 0.3 0 0 0 0.3 -0.3c0.05 -0.37 0.19 -0.94 0.27 -1.6 0 -0.22 0 -0.45 0.05 -0.69a5.3 5.3 0 0 0 0 -0.69c0 -0.71 -0.09 -1.31 -0.11 -1.61a0.32 0.32 0 0 0 -0.32 -0.36Z"
                />
                <path d="M16.22 2.28a0.92 0.92 0 0 0 -0.81 1.64c0.86 0.52 2.05 -1.13 0.81 -1.64Z"/>
                <g>
                    <path
                        d="m23.65 5.19 0 -3.77a1.28 1.28 0 0 0 -0.29 -0.79A5.62 5.62 0 0 0 21.37 0.2c-1.21 -0.12 -2.82 -0.2 -4 -0.2 -0.37 0 -4.25 0 -6.5 0.17A4.6 4.6 0 0 0 9 0.56a0.61 0.61 0 0 0 -0.18 0.31 6.41 6.41 0 0 0 -0.15 1.28c-0.1 1.74 -0.07 4.66 -0.06 5.35a0.34 0.34 0 0 0 0.39 0.33 0.34 0.34 0 0 0 0.34 -0.33c0 -0.53 0 -2.37 0.09 -4 0 -0.84 0.09 -1.6 0.16 -2.08a2.84 2.84 0 0 1 0.06 -0.29l0.35 0C10.58 1 11.53 1 12.54 1l4.85 0c0.95 0 2.23 0.11 3.31 0.21 0.7 0.07 1.75 0.24 1.77 0.25s0.05 2.88 0 3.69c0 0.49 0.08 2.23 0.08 3.74 0 0.77 -0.08 2.18 -0.09 2.21a4.81 4.81 0 0 0 -0.71 0l-1.06 0c-1 -0.05 -2.15 -0.15 -2.67 -0.12a0.84 0.84 0 0 0 -0.59 0.22c-0.24 0.28 -0.49 0.93 -0.92 1.65a6.34 6.34 0 0 1 -0.67 0.9 2.15 2.15 0 0 1 -1.58 0.7c-0.08 -0.55 0 -2 0 -2.69a1 1 0 0 0 -0.08 -0.41 0.32 0.32 0 0 0 -0.25 -0.2 4 4 0 0 0 -0.64 0 5.64 5.64 0 0 0 -0.65 0 0.28 0.28 0 0 0 -0.24 0.34 0.29 0.29 0 0 0 0.33 0.25 3.2 3.2 0 0 1 0.56 0h0.33a28.12 28.12 0 0 0 0 3.1 0.44 0.44 0 0 0 0.33 0.25 2.26 2.26 0 0 0 1 0 3 3 0 0 0 1.42 -0.81 5.7 5.7 0 0 0 0.81 -1c0.37 -0.57 0.59 -1.11 0.8 -1.4 0.5 0 1.57 0.11 2.55 0.2a11.3 11.3 0 0 0 1.65 0 1.71 1.71 0 0 0 1 -0.34 3.15 3.15 0 0 0 0.38 -1.55c0.2 -1.62 0.08 -4.36 0.09 -5Z"
                    />
                    <path
                        d="M12 19.81a6 6 0 0 0 -2 -2 5.61 5.61 0 0 0 -1.24 -0.49 5.85 5.85 0 0 0 2.17 -2.55 3.93 3.93 0 0 0 0.12 -3.42c-0.52 -1 -4.8 -3.89 -7.86 0 -1.38 1.76 -1.68 4.21 0.34 5.67a5.65 5.65 0 0 0 1.31 0.68 8.11 8.11 0 0 0 -3.22 2.81A7.35 7.35 0 0 0 1 21.74a13.28 13.28 0 0 0 -0.66 1.88 0.29 0.29 0 0 0 0.17 0.38 0.3 0.3 0 0 0 0.37 -0.2 15.27 15.27 0 0 1 0.61 -1.46A7.31 7.31 0 0 1 2.3 21a7.49 7.49 0 0 1 3.34 -2.42 4.72 4.72 0 0 1 5.64 1.72 11.54 11.54 0 0 1 1.66 3.36 0.34 0.34 0 1 0 0.65 -0.18A12.86 12.86 0 0 0 12 19.81Zm-8.1 -7.89c2.57 -3.63 6.22 -0.55 6.35 0a3.26 3.26 0 0 1 -0.19 2.52 4.59 4.59 0 0 1 -2.6 2.35 3.72 3.72 0 0 1 -3.3 -0.62c-1.52 -1.07 -1.16 -2.9 -0.23 -4.25Z"
                    />
                </g>
            </g>
        </svg>
    );
}