/**
 * Created by Kitty Dev www.kitty-dev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kitty-dev.com
 * Date: 17/04/24
 * Time: 10:31 PM
 * Project: kittydev_landing_site_react
 */
import React, {memo} from 'react';
import {Link} from "react-router-dom";

export const Footer = memo(() => {
    const year = new Date().getFullYear()
    const handleRefundPolicyClick = () => {
        // Scroll to the top of the page
        window.scrollTo({top: 0, behavior: 'smooth'});
    };
    return (
        <section className="footer-container pt-ul">
            <div className="footer-logo-container">
                <div className="footer-logo-masker"/>

                <img alt={"kittxdev-logo-mini"}
                     className="footer-logo"
                     src={"https://ik.imagekit.io/kittydev/KittXDev/KittXdev_x_white_icon_uOCkfhTIz.svg"}
                />
            </div>
            <div className="footer-holder">
                <div>
                    <p className="secondary-Text description-text">
                        KITTXDEV TECHNOLOGIES PRIVATE LIMITED © Copyright {year} | All Rights Reserved.
                    </p>
                </div>
                <div className="footer-contact-holder">
                    <Link to={"/terms-and-conditions"} onClick={handleRefundPolicyClick}
                          className={"description-text secondary-Text"}>Terms and
                        conditions</Link>
                    <p className={"description-text secondary-Text"}>|</p>
                    <Link to={"/privacy-policy"} onClick={handleRefundPolicyClick}
                          className={"description-text secondary-Text"}>Privacy policy</Link>
                    <p className={"description-text secondary-Text"}>|</p>
                    <Link to={"/refund-policy"} onClick={handleRefundPolicyClick}
                          className={"description-text secondary-Text"}>Refund
                        policy</Link>
                </div>
            </div>
        </section>
    );
})